<template>
    <div class="road_info_management">
        <jy-query class="query" ref="checkForm" :model="formData">
            <jy-query-item label="开始日期" prop="sDate" span="1.5">
                <el-date-picker v-model="formData.sDate" :picker-options="startTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item label="结束日期" prop="eDate" span="1.5">
                <el-date-picker v-model="formData.eDate" :picker-options="endTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item prop="routeId" label="线路" span="1.5">
                <el-select v-model="formData.routeIds" multiple>
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="车辆" span="1.5">
                <el-select v-model="formData.vehicleNo" placeholder="请选择" clearable>
                    <el-option v-for="item in vehicleList" :key="item.key" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" v-on:click="search" v-if="btnexist('scheduleRoadBillInfoManagementList')">查询</el-button>
                <el-button type="primary" v-on:click="reset" v-if="btnexist('scheduleRoadBillInfoManagementReset')">重置</el-button>
            </template>
        </jy-query>
        <jy-table ref="jyTable" :data="dataList"  class="table" height="480">
            <jy-table-column type="index" label="序号" width="60" fixed></jy-table-column>
            <jy-table-column prop="oDate" label="日期" width="100" ></jy-table-column>
            <jy-table-column prop="routeNa" label="线路名称" min-width="100" ></jy-table-column>
            <jy-table-column prop="dFromLine" label="线路类型" width="80">
                <template slot-scope="scope">{{scope.row.schemeT |schemeT }}</template>
            </jy-table-column>
            <jy-table-column prop="customNo" label="自编号" width="95" show-overflow-tooltip ></jy-table-column>
            <jy-table-column prop="vehicleNo" label="车牌号" width="95" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="driverNa" label="司机" width="80" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="sSiteNa" label="起点站" width="135" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="eSiteNa" label="终点站" width="135" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="sTime" label="发车时间" width="80"></jy-table-column>
            <jy-table-column prop="eTime" label="结束时间" width="80"></jy-table-column>
            <jy-table-column prop="runM" label="实际运行里程(km)" width="80" show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="eState" label="运行情况" min-width="80" show-overflow-tooltip :formatter="runStatusFil"></jy-table-column>
            <jy-table-column prop="dFromLine" label="是否偏离" width="80">
                <template slot-scope="scope">{{scope.row.dFromLine |dFromLine }}</template>
            </jy-table-column>
            <jy-table-column prop="noDeviations" label="偏离值" width="80"></jy-table-column>
            <jy-table-column prop="verify" label="审核状态" min-width="110" show-overflow-tooltip :formatter="auditStatusFil"></jy-table-column>
            <jy-table-column prop="remark" label="备注" min-width="120" show-overflow-tooltip></jy-table-column>
            <jy-operate  :list="butList"></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
            :count="5">
        </jy-pagination>
        <addUpdate ref="addUpdate" v-on:updateList="getList"></addUpdate>
        <detail ref="detail"></detail>
        <site ref="site"></site>
        <track-replay ref="trackReplay"></track-replay>
        <audit ref="audit" @updateList="getList"></audit>
    </div>
</template>
<script>
import { btnMixins } from '@/common/js/button.mixin'
import addUpdate from '@/components/pages/admin/roadBillInfo/management/addUpdate.vue'
import detail from '@/components/pages/admin/roadBillInfo/management/detail.vue'
import site from '@/components/pages/admin/roadBillInfo/management/site.vue'
import trackReplay from "@/components/pages/admin/common/trackReplay/trackReplay";
import audit from '@/components/pages/admin/roadBillInfo/audit/audit.vue'
import { getAuditStatus, getRunStatus } from '@/common/js/wordbook.js'
export default {
    data() {
        let auditStatus = getAuditStatus()
        let runStatus = getRunStatus()
        return {
            btnMenuId: 'scheduleRoadBillInfoManagement',
            butList: [],
            dataList: [],
            auditStatus,
            runStatus,
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            formData: {
                sDate: '',
                eDate: '',
                routeIds: [],
                customNo: '',
                vehicleNo: '',
                driverNa: '',
                eState: '',
                verify: '',
            },
            routeList: [],
            vehicleList: [],
            routeInfo: '',
            startTime: {
                disabledDate:(time) => {
                    if (this.formData.eDate) {
                        return (
                        time.getTime() > new Date(this.formData.eDate).getTime()
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
            endTime: {
                disabledDate:(time) => {
                    if (this.formData.sDate) {
                        return (
                            time.getTime() > Date.now() ||
                            time.getTime() < new Date(this.formData.sDate).getTime() -8.64e7
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
        }
    },
    filters: {
        dFromLine (val) {
            if (val == '0') {
                return '正常'
            } else if (val == '1') {
                return '偏离'
            }
        },
        schemeT (val) {
            if (val == '1') {
                return '上行'
            } else if (val == '2') {
                return '下行'
            }
        }
    },
    mixins: [btnMixins],
    components: { addUpdate, detail, site, trackReplay, audit },
    created() {
        this.setButList()
        // this.getDate()
        this.getButtons("scheduleRoadBillInfoManagement")
        this.getButtons("scheduleRoadBillInfoAudit")
    },
    methods: {
        init(options) {
            this.routeInfo = options
            this.getRouteList()
            this.getVehicles()
            this.getList()
        },
        getDate(){
            let now = new Date()
            this.formData.oDate =  now.Format('yyyy-MM-dd')
        },
        getRouteList() {
            // let url = '/route/info/querypage'
            // let option = {
            //     orgId: this.routeInfo.id,
            //     pageIndex: 1,
            //     pageSize: 10
            // }
            // this.$http.post(url,option).then(({ detail }) => {
            //     console.log(detail)
            //     if(detail.list.length > 0) {
            //         let list = detail.list.map(item=> {
            //             return {
            //                 ...item.routeInfoVO
            //             }
            //         })
            //         this.routeList = list
            //     }
            // })
            this.routeList = [{
                routeId: "622f1316e4b0594b3b2b4644",
                cName: "901路"
            }]
        },
        getVehicles() {
            // let url ="/base/vehicle/getVehiclesByOrgId"
            // let option = {
            //     orgId: this.routeInfo.id
            // }
            // this.$http.post(url, option).then(({ detail }) => {
            //     this.vehicleList = []
            //     detail.map(item=>{
            //         let v = {
            //             label: item.plateNum,
            //             value: item.plateNum,
            //             key: item.vehicleId
            //         }
            //         this.vehicleList.push(v)
            //     })
            // })
            this.vehicleList = [{
                label: "浙B98103",
                value: "6231b714e4b02be9fe7fba71",
            }]
        },
        getList() {
            let url = '/waybillActual/queryPage'
            let params = {
                driverNa:this.routeInfo.text,
                eState: '2',
                pageIndex: this.pageIndex,
                pageSize: this.pageSize,
            }
            Object.keys(this.formData).forEach(key => {
                let d = this.formData[key]
                if (key=='routeIds') {
                    d = d.join(',')
                }
                if (!/^\s*$/.test(d)) {
                    params[key] = d
                }
            })
            this.$http.post(url, params).then(({ detail }) => {
                console.log(detail.list)
                this.dataList = detail.list
                this.total = detail.total
            })
        },
        auditStatusFil(row, column, cellVal) {
            let label = ''
            this.auditStatus.forEach(one => {
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        runStatusFil(row, column, cellVal) {
            let label = ''
            this.runStatus.forEach(one => {
                if (one.value == cellVal) {
                    label = one.label
                }
            })
            return label
        },
        setButList() {
            let list = [{
                name: '路单修改',
                fun: (row) => {
                    this.$refs.addUpdate.init(row, 1)
                },
                isShow: (row) => { return this.btnexist('scheduleRoadBillInfoManagementUpdate')&&row.verify == '0' }
            },
            {
                name: '完整信息',
                fun: (row) => {
                    this.$refs.detail.init(row)
                },
                isShow: () => { return this.btnexist('scheduleRoadBillInfoManagementView') }
            }, {
                name: '过站明细',
                fun: (row) => {
                    this.$refs.site.init(row)
                },
                isShow: () => { return this.btnexist('scheduleRoadBillInfoManagementSite') }
            }, {
                name: '轨迹回放',
                fun: (row) => {
                    this.$refs.trackReplay.init(row, "waybill");
                },
                isShow: () => { return this.btnexist('scheduleRoadBillInfoManagementTrack') }
            },{
                name: '审核',
                fun: (row) => {
                    this.onAudit(row)
                },
                isShow: () => { return this.btnexist('scheduleRoadBillInfoAuditVerity') }
            }]
            this.butList = list
        },
        search() {
            this.pageIndex = 1
            this.getList()
        },
        reset() {
            this.$refs.checkForm.resetFields()
            // this.getDate()
            this.search()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
        handleCurrentChange(val) {
            this.pageIndex = val
            this.getList()
        },
        // 提交审核
        onAudit(row) {
            this.$refs.audit.init(row, "single");
        },
    }
}

</script>
<style lang="scss" scoped="road_info_management">
.road_info_management {
    max-height: 680px;
    .query {
        margin-top:10px;
    }
    .table {
        font-size: 12px;
    }
}
</style>
