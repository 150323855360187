<template>
    <el-dialog title="设备状态" :visible.sync="visible" width="30%">
        <div class="content">
            <p>设备:{{ device.clientId }}</p>
            <p>所属组织:{{ device.orgName }}</p>
            <p>在线状态:{{ device.online == 1 ? "在线" : "离线" }}</p>
            <p>时间:{{ device.time }}</p>
            <p>车牌号:{{ device.plateNum }}</p>
            <p>经度:{{ device.lng }} （百度坐标系）</p>
            <p>纬度:{{ device.lat }}</p>
            <p>地址:{{ device.address }}</p>
        </div>
    </el-dialog>
</template>

<script>
import { getCompleteAddressByPoint } from "@/common/js/BMapUtil";
import gcoord from "gcoord";
// 展示设备状态
export default {
    name: "device",
    data() {
        return {
            visible: false,
            device: {
                plateNum: "",
                orgName: ""
            }
        };
    },
    methods: {
        async init(option) {
            this.device.plateNum = option.plateNum;
            this.device.orgName = option.orgName;
            this.$http.post("/base/device/getDeviceOnLineStatus", [option.deviceId]).then(({ detail }) => {
                if (detail && detail.length) {
                    this.device = detail[0];
                    const { lng, lat } = this.device;
                    const p = gcoord.transform([lng, lat], gcoord.WGS84, gcoord.BD09);
                    this.device.lng = p[0];
                    this.device.lat = p[1];
                    this.device.time = this.$util.fixedMacTime(this.device.time);
                    getCompleteAddressByPoint(p[0], p[1]).then(rs => {
                        this.$set(this.device, "address", rs.address);
                    });
                }
            });

            this.visible = true;
        }
    }
};
</script>

<style scoped></style>
