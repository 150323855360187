<template>
    <div class="road_info_management">
        <jy-table ref="jyTable" :data="dataList"  class="table">
            <jy-table-column prop="plateNum" label="车牌号码" width="100" ></jy-table-column>
            <jy-table-column prop="vin" label="vin" min-width="100" ></jy-table-column>
            <jy-table-column label="车辆类型" min-width="100">
                <template slot-scope="scope">{{ scope.row.vType | vType }}</template>
            </jy-table-column>
            <jy-table-column prop="deviceIds" label="关联设备" width="95" ></jy-table-column>
            <jy-table-column label="状态" min-width="100">
                <template slot-scope="scope">{{ scope.row.status | status }}</template>
            </jy-table-column>
        </jy-table>
        <div class="total">
            <span>车辆数: 10辆</span>
            <span>正常运营: 10辆</span>
            <span>停运: 0辆</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            btnMenuId: 'scheduleRoadBillInfoManagement',
            butList: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            formData: {
                oDate: '',
                routeIds: [],
                customNo: '',
                vehicleNo: '',
                driverNa: '',
                eState: '',
                verify: '',
            },
            routeList: [],
            routeInfo: ''
        }
    },
    filters: {
        vType(val) {
                if (val == 0) {
                    return "公交车";
                } else if (val == 1) {
                    return "货车";
                }
            },
        status(val) {
            let v = "";
            switch (val) {
                case "1":
                    v = "正常";
                    break;
                case "2":
                    v = "停运";
                    break;
                case "3":
                    v = "维修";
                    break;
                case "4":
                    v = "报废";
                    break;
            }
            return v;
        },
    },
    created() {
    },
    methods: {
        init(options) {
            this.routeInfo = options
            this.getList()
        },
        getList() {
            this.dataList = [{
                plateNum:"浙BD7788D",
                vin: "BD7788D",
                vType:0,
                deviceIds: "014466365701",
                status: "1"
            },{
                plateNum:"浙B5A732",
                vin: "B5A732",
                vType:0,
                deviceIds: "014466365702",
                status: "1"
            },{
                plateNum:"浙B9A739",
                vin: "B9A739",
                vType:0,
                deviceIds: "014466365703",
                status: "1"
            },{
                plateNum:"浙B5A753",
                vin: "B5A753",
                vType:0,
                deviceIds: "014466365704",
                status: "1"
            }]
        }
    }
}

</script>
<style lang="scss" scoped="road_info_management">
.road_info_management {
    max-height: 600px;
    overflow-y: auto;
    .query {
        width:710px;
        margin-top:10px;
    }
    .table {
        font-size: 12px;
    }
    .total {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 54px;
        background: inherit;
        // background-color: rgba(215, 215, 215, 1);
        padding: 20px;
        font-size: initial;
    }
}
</style>
