<template>
    <div>
        <el-divider></el-divider>
        <jy-table ref="table" class="panel" :data="alarmList">
            <jy-table-column type="index" label="序号"></jy-table-column>
            <jy-table-column label="报警类型" prop="alertType"></jy-table-column>
            <jy-table-column prop="time" label="报警时间" :formatter="formatter"></jy-table-column>
            <jy-table-column :minWidth="120" label="报警地址" prop="alarmAddress"></jy-table-column>
        </jy-table>
        <el-divider></el-divider>
        <div class="search">
            <el-button size="mini" type="primary" @click="navToHandle">报警数据</el-button>
        </div>
        <el-divider></el-divider>
    </div>
</template>

<script>
    // import { getCompleteAddressByPoint } from "@/common/js/BMapUtil";
    export default {
        destroyed() {
            this.alarmList = [];
        },
        methods: {
            navToHandle() {
                // this.$router.push({
                //     name: "intelligent",
                //     params: {
                //         vehicleNo: this.vehicleNo
                //     }
                // });
            },
            formatter(row, column, cellValue) {
                return cellValue.$FixedMacTime().split(" ")[1];
            },
            init(vehicleNo) {
                this.vehicleNo = vehicleNo;
            }
        },
        data() {
            return {
                alarmList: [],
                vehicleNo: ""
            };
        }
    };
</script>
<style lang="scss" scoped>
    .panel {
        max-height: 360px;
        overflow-y: auto;
    }
    .search {
        text-align: center;
    }
</style>
