/** @format */

class DVI4 {
  constructor() {
    this.encodeState = {
      valpred: 0,
      index: 0
    };
    this.decodeState = {
      valpred: 0,
      index: 0
    };
    this.flag = 0;
  }

  /**
   * pcm 转 adpcm_dvi4
   * @param {Int16Array} data 320 bytes
   * @returns Int8Array 164 bytes
   */
  fromPcm(data, hisi) {
    /**
         * Typedef struct
            {
                short  sample0;    //block中第一个采样值（未压缩）
                BYTE  index;     //上一个block最后一个index，第一个block的index=0;
                BYTE  reserved;   //尚未使用
            }MonoBlockHeader;
         */
    const dlen = data.length * 2;
    let h = new Int8Array(4);
    h[0] = this.ubyte2tyte(this.encodeState.valprev & 0x0ff);
    h[1] = this.ubyte2tyte((this.encodeState.valprev >> 8) & 0x0ff);
    if (0 == this.flag) {
      this.flag = 1;
      this.encodeState.index = 0x00;
      this.encodeState.valprev = 0x0000;
    }
    h[2] = this.encodeState.index;
    h[3] = 0;

    let outdata = new Int8Array(dlen / 4);
    this.adpcm_coder(data, outdata, dlen / 2, this.encodeState);

    let hisiHeader = new Int8Array(4),
      int8;
    if (hisi) {
      hisiHeader.set([0x00, 0x01, (dlen / 4 + 4) / 2, 0x00]);
      int8 = new Int8Array(dlen / 4 + 8);
      int8.set(hisiHeader, 0);
      int8.set(h, 4);
      int8.set(outdata, 8);
    } else {
      int8 = new Int8Array(dlen / 4 + 4);
      int8.set(h, 0);
      int8.set(outdata, 4);
    }

    return int8;
  }

  /**
   * adpcm_dvi4 转 pcm
   * @param {Uint8Array} data
   * @returns Int16Array
   */
  toPcm(data) {
    // State state = new State();
    const newData = new Int8Array(data.buffer);
    let dlen = newData.length / 2;
    let temp;
    // 如果前四字节是00 01 52 00，则是海思头，需要去掉，否则就视为普通的ADPCM编码
    if (
      newData[0] == 0x00 &&
      newData[1] == 0x01 &&
      (newData[2] & 0xff) == (newData.length - 4) / 2 &&
      newData[3] == 0x00
    ) {
      dlen = (newData.length - 8) / 2;
      temp = new Int8Array(newData.length - 8);
      temp.set(newData.subarray(8), 0);
      this.decodeState.valprev = this.ushort2short(
        ((newData[5] << 8) & 0xff00) | (newData[4] & 0xff)
      );
      this.decodeState.index = newData[6];
    } else {
      dlen = (newData.length - 4) / 2;
      temp = new Int8Array(newData.length - 4);
      temp.set(newData.subarray(4), 0);

      this.decodeState.valprev = this.ushort2short(
        ((newData[1] << 8) & 0xff00) | (newData[0] & 0xff)
      );
      this.decodeState.index = newData[2];
    }
    // 压缩比为4:1，所以还原的时候需要*4
    let outdata = new Int16Array(dlen * 4);
    this.adpcm_decoder(temp, outdata, dlen * 4, this.decodeState);
    return outdata;
  }

  adpcm_coder(indata, outdata, len, state) {
    let val; /* Current input sample value */
    let sign; /* Current adpcm sign bit */
    let delta; /* Current adpcm output value */
    let diff; /* Difference between val and valprev */
    let step; /* Stepsize */
    let valpred; /* Predicted output value */
    let vpdiff; /* Current change to valpred */
    let index; /* Current step change index */
    let outputbuffer = 0; /* place to keep previous 4-bit value */
    let bufferstep; /* toggle between outputbuffer/output */

    let outp = outdata;
    let inp = indata;

    valpred = state.valprev;
    index = state.index;
    step = DVI4.stepsizeTable[index];

    bufferstep = 1;

    let k = 0;
    for (let i = 0; len > 0; len--, i++) {
      val = inp[i];

      /* Step 1 - compute difference with previous value */
      diff = val - valpred;
      sign = diff < 0 ? 8 : 0;
      if (sign != 0) {
        diff = -diff;
      }

      /* Step 2 - Divide and clamp */
      /* Note:
       ** This code *approximately* computes:
       **    delta = diff*4/step;
       **    vpdiff = (delta+0.5)*step/4;
       ** but in shift step bits are dropped. The net result of this is
       ** that even if you have fast mul/div hardware you cannot put it to
       ** good use since the fixup would be too expensive.
       */
      delta = 0;
      vpdiff = step >> 3;

      if (diff >= step) {
        delta = 4;
        diff -= step;
        vpdiff += step;
      }
      step >>= 1;
      if (diff >= step) {
        delta |= 2;
        diff -= step;
        vpdiff += step;
      }
      step >>= 1;
      if (diff >= step) {
        delta |= 1;
        vpdiff += step;
      }

      /* Step 3 - Update previous value */
      if (sign != 0) {
        valpred -= vpdiff;
      } else {
        valpred += vpdiff;
      }

      /* Step 4 - Clamp previous value to 16 bits */
      if (valpred > 32767) {
        valpred = 32767;
      } else if (valpred < -32768) {
        valpred = -32768;
      }

      /* Step 5 - Assemble value, update index and step values */
      delta |= sign;

      index += DVI4.indexTable[delta];
      if (index < 0) {
        index = 0;
      }
      if (index > 88) {
        index = 88;
      }
      step = DVI4.stepsizeTable[index];

      /* Step 6 - Output value */
      if (bufferstep != 0) {
        outputbuffer = (delta << 4) & 0xf0;
      } else {
        outp[k++] = this.ubyte2tyte((delta & 0x0f) | outputbuffer);
      }
      bufferstep = bufferstep == 0 ? 1 : 0;
    }

    /* Output last step, if needed */
    if (bufferstep == 0) {
      outp[k++] = this.ubyte2tyte(outputbuffer);
    }

    state.valprev = this.ushort2short(valpred);
    state.index = index;
  }

  /**
   *
   * @param {Int8Array} indata
   * @param {Uint16Array} outdata
   * @param {number} len
   * @param {*} state
   */
  adpcm_decoder(indata, outdata, len, state) {
    // signed char *inp;		/* Input buffer pointer */
    // short *outp;		/* output buffer pointer */
    let sign; /* Current adpcm sign bit */
    let delta; /* Current adpcm output value */
    let step; /* Stepsize */
    let valpred; /* Predicted value */
    let vpdiff; /* Current change to valpred */
    let index; /* Current step change index */
    let inputbuffer = 0; /* place to keep next 4-bit value */
    let bufferstep; /* toggle between inputbuffer/input */

    let outp = outdata;
    let inp = indata;

    valpred = state.valprev;
    index = state.index;
    if (index < 0) {
      index = 0;
    }
    if (index > 88) {
      index = 88;
    }
    step = DVI4.stepsizeTable[index];

    bufferstep = 0;

    let k = 0;
    for (let i = 0; len > 0; len--) {
      /* Step 1 - get the delta value */
      if (bufferstep != 0) {
        delta = inputbuffer & 0xf;
      } else {
        inputbuffer = inp[i++];
        // console.log(inputbuffer);
        delta = (inputbuffer >> 4) & 0xf;
      }
      bufferstep = bufferstep == 0 ? 1 : 0;

      /* Step 2 - Find new index value (for later) */
      index += DVI4.indexTable[delta];
      if (index < 0) {
        index = 0;
      }
      if (index > 88) {
        index = 88;
      }

      /* Step 3 - Separate sign and magnitude */
      sign = delta & 8;
      delta = delta & 7;

      /* Step 4 - Compute difference and new predicted value */
      /*
       ** Computes 'vpdiff = (delta+0.5)*step/4', but see comment
       ** in adpcm_coder.
       */
      vpdiff = step >> 3;
      if ((delta & 4) > 0) {
        vpdiff += step;
      }
      if ((delta & 2) > 0) {
        vpdiff += step >> 1;
      }
      if ((delta & 1) > 0) {
        vpdiff += step >> 2;
      }

      if (sign != 0) {
        valpred -= vpdiff;
      } else {
        valpred += vpdiff;
      }

      /* Step 5 - clamp output value */
      if (valpred > 32767) {
        valpred = 32767;
      } else if (valpred < -32768) {
        valpred = -32768;
      }

      /* Step 6 - Update step value */
      step = DVI4.stepsizeTable[index];

      /* Step 7 - Output value */
      outp[k++] = valpred;
    }

    state.valprev = valpred;
    state.index = index;
  }

  // 无符号byte 转 有符号byte
  ubyte2tyte(val) {
    const value = val & 0xff;
    if (value < Math.pow(2, 7)) {
      return value;
    } else {
      return value - Math.pow(2, 8);
    }
  }

  /**
   * 无符号short 转 有符号 short
   * @param {number} val
   * @returns 有符号short
   */
  ushort2short(val) {
    const value = val & 0xffff;
    if (value < Math.pow(2, 15)) {
      return value;
    } else {
      return value - Math.pow(2, 16);
    }
  }

  static indexTable = [-1, -1, -1, -1, 2, 4, 6, 8, -1, -1, -1, -1, 2, 4, 6, 8];

  static stepsizeTable = [
    7,
    8,
    9,
    10,
    11,
    12,
    13,
    14,
    16,
    17,
    19,
    21,
    23,
    25,
    28,
    31,
    34,
    37,
    41,
    45,
    50,
    55,
    60,
    66,
    73,
    80,
    88,
    97,
    107,
    118,
    130,
    143,
    157,
    173,
    190,
    209,
    230,
    253,
    279,
    307,
    337,
    371,
    408,
    449,
    494,
    544,
    598,
    658,
    724,
    796,
    876,
    963,
    1060,
    1166,
    1282,
    1411,
    1552,
    1707,
    1878,
    2066,
    2272,
    2499,
    2749,
    3024,
    3327,
    3660,
    4026,
    4428,
    4871,
    5358,
    5894,
    6484,
    7132,
    7845,
    8630,
    9493,
    10442,
    11487,
    12635,
    13899,
    15289,
    16818,
    18500,
    20350,
    22385,
    24623,
    27086,
    29794,
    32767
  ];
}

export default DVI4;
