import BMap from "BMap";

/**
 *
 * @param {Object} map map实例
 * @param {*} curPos 当前经纬度
 * @param {*} targetPos 下一个经纬度
 * @returns deg
 */
export function getRotation(map, curPos, targetPos) {
    let deg = 0;
    curPos = map.coordToPoint(curPos);
    targetPos = map.coordToPoint(targetPos);

    if (targetPos.x != curPos.x) {
        const tan = (targetPos.y - curPos.y) / (targetPos.x - curPos.x),
            atan = Math.atan(tan);
        deg = (atan * 360) / (2 * Math.PI);
        if (targetPos.x < curPos.x) {
            deg = -deg + 90 + 90;
        } else {
            deg = -deg;
        }
        if (!deg) {
            // 防止为0度时不发生翻转
            deg += 0.01;
        }
        return -deg;
    } else {
        const disy = targetPos.y - curPos.y;
        let bias = 0;
        if (disy > 0) {
            bias = -1;
        } else {
            bias = 1;
        }
        return -bias * 90;
    }
}

/**
 *
 * @param {number|string} lng
 * @param {number|string} lat
 * @param {boolean} plusSuround 直接返回带poi的地址字符串
 * @see 此方法比直接调用百度接口更加稳定，不易报错
 */
export function getCompleteAddressByPoint(lng, lat, plusSuroundPOI = false) {
    return new Promise(resolve => {
        const pt = new BMap.Point(Number(lng), Number(lat));
        new BMap.Geocoder().getLocation(
            pt,
            function(rs) {
                if (plusSuroundPOI) {
                    if (rs.surroundingPois && rs.surroundingPois.length) {
                        resolve(rs.address + " " + rs.surroundingPois[0].address);
                    } else {
                        resolve(rs.address);
                    }
                } else {
                    resolve(rs);
                }
            },
            {
                poiRadius: 50,
                numPois: 1
            }
        );
    });
}
