<template>
	<el-aside width="2px" @mousedown.native="openDrag" @mouseup.native="closeDrag"></el-aside>
</template>

<script>
	export default {
		created() {
			const _this = this;
			document.addEventListener("mousemove", function (e) {
				if (_this.canDrag) {
					if (e.clientX > 600 || e.clientX < 300) {
						_this.canDrag = false;
					}
					_this.$emit("dragx", e.clientX);
				}
			});
		},
		destroyed() {
			document.removeEventListener("mousemove", function () {});
		},
		methods: {
			openDrag() {
				this.canDrag = true;
			},
			closeDrag() {
				this.canDrag = false;
			},
		},
		data() {
			return {
				canDrag: false,
			};
		},
	};
</script>
<style lang="scss" scoped>
	.el-aside {
		z-index: 99;
		cursor: e-resize;
		user-select: none;
		background-image: linear-gradient(to right, #ddd, transparent);
	}
</style>