<template>
    <div class="list-wrapper">
        <jy-table :data="list" class="classList" height="480">
            <!-- <jy-table-column type="index" width="50" label="序号" fixed="left"></jy-table-column> -->
            <jy-table-column prop="shiftNum" label="班次" ></jy-table-column>
            <jy-table-column prop="dtime" label="计划发车时间" ></jy-table-column>
            <jy-table-column prop="otime" label="计划结束时间" ></jy-table-column>
            <jy-table-column prop="vehicleNo" label="计划发车车辆"  ></jy-table-column>
            <jy-table-column prop="driverNa" label="计划发车司机" ></jy-table-column>
            <jy-table-column prop="ssiteNa" label="起始站" ></jy-table-column>
            <jy-table-column prop="esiteNa" label="终点站" ></jy-table-column>
            <jy-table-column label="线路方向" >
                <template slot-scope="scope">{{ scope.row.schemeT | schemeT }}</template>
            </jy-table-column>
            <jy-table-column label="营运状态" >
                <template slot-scope="scope">{{ scope.row.operationT | operationT }}</template>
            </jy-table-column>
            <jy-table-column label="班次类型" >
                <template slot-scope="scope">{{ scope.row.type | type }}</template>
            </jy-table-column>
            <jy-table-column prop="remark" label="备注" ></jy-table-column>
            <jy-operate v-bind:list="operationList" ></jy-operate>
        </jy-table>
        <jy-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageIndex"
            :page-size="pageSize"
            :total="total"
        ></jy-pagination>
    </div>
</template>

<script>
    export default {
        filters: {
            formatAeState(val) {
                switch (val) {
                    case "1":
                        return "已发车";
                    case "2":
                        return "已完成";
                    case "3":
                        return "过时未发车";
                    case "4":
                        return "异常订单";
                }
            },
            formatState(val) {
                switch (val) {
                    case "0":
                        return "未发车";
                    case "1":
                        return "取消发车";
                    case "2":
                        return "过时未发车";
                    case "3":
                        return "执行中";
                    case "4":
                        return "已完成";
                    case "5":
                        return "异常订单";
                }
            },
            isActive(val) {
                if (val == 0) {
                    return "无效";
                } else if (val == 1) {
                    return "有效";
                }
            },
            schemeT(val) {
                if (val == 1) {
                    return "上行";
                } else if (val == 2) {
                    return "下行";
                }
            },
            isCrossL(val) {
                if (val == 1) {
                    return "未跨线路";
                } else if (val == 2) {
                    return "跨线路";
                }
            },
            operationT(val) {
                if (val == 0) {
                    return "营运";
                } else if (val == 1) {
                    return "非营运";
                }
            },
            type(val) {
                if (val == 1) {
                    return "普通班";
                } else if (val == 2) {
                    return "加班";
                } else if (val == 3) {
                    return "夜班";
                } else {
                    return val
                }
            },
        },
        methods: {
            init(plateNum) {
                this.vehicleNo = plateNum;
                this.getList();
                this.seOperation();
            },
            seOperation(){
                this.operationList = [
                    {
                        name: '发车通知',
                        fun: (item) => {
                            this.sedCar(item)
                        },
                        isShow: () => {
                            return true
                        }
                    },
                    {
                        name: '编辑',
                        fun: (item) => {
                            this.addUdate(item)
                        },
                        isShow: () => {
                            return true
                        }
                    },
                    {
                        name: '复制',
                        fun: (item) => {
                            this.copy(item)
                        },
                        isShow: () => {
                            return true
                        }
                    },
                    {
                        name: '删除',
                        fun: (item) => {
                            this.del(item)
                        },
                        isShow: () => {
                            return true
                        }
                    },
                ]
            },
            getList() {
                let url = "/waybillPlan/queryPageWaybillInfo";
                this.$http
                    .post(url, {
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize,
                        vehicleNo: this.vehicleNo,
                        oDate: new Date().Format("yyyy-MM-dd")
                    })
                    .then(({ detail }) => {
                        let num = 1;
                        detail.map(item=>{
                            item.shiftNum = "班次"+num;
                            num++;
                        })
                        console.log(detail)
                        this.list = detail;
                        this.total = this.list.length;
                    });
            },
            handleSizeChange(val) {
                console.log(`每页 ${val} 条`);
                this.pageSize = val;
                this.getList();
            },
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`);
                this.pageIndex = val;
                this.getList();
            },
            addUdate(option) {
                if (option) {
                    this.$refs.npm.init(option, 'update')
                } else {
                    let option = {
                        routeId:this.routeInfo.id,
                        routeNa:this.routeInfo.text,
                        orgId: this.routeInfo.porgId,
                        oDate:this.$util.getNowDate(),
                        schemeId:this.schemeId,
                    }
                    this.$refs.npm.init(option,'add')
                }
            },
            sedCar(item){
                this.isInit = false
                console.log(item)
                let url = '/waybillPlan/depNotice'
                let option = {
                    waybillId:item.waybillId
                }
                this.$http.post(url,option).then(res=>{
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.getList()
                })
            },
            copy(item){
                this.isInit = false
                let url = '/waybillPlan/add'
                let option = {
                    ...item,
                    schemeId:this.schemeId,
                }
                this.$http.post(url,option).then(res=>{
                    this.$message({
                        message: res.msg,
                        type: 'success',
                    });
                    this.getList()
                })
            },
            del(item){
                this.isInit = false
                this.$confirm('是否删除该班次?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let url = '/waybillPlan/del'
                        let option = {
                            waybillId:item.waybillId
                        }
                        this.$http.post(url,option).then(res=>{
                            this.$message({
                                message: res.msg,
                                type: 'success',
                            });
                            this.getList()
                        })
                    })
                    .catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消删除',
                        })
                    })
            },
        },
        data() {
            return {
                pageIndex: 1,
                pageSize: 10,

                list: [],
                total: 0,
                vehicleNo: "",
                operationList:[],
            };
        }
    };
</script>
<style lang="scss" scoped="list-wrapper">
    .list-wrapper{
        overflow: auto;
        .classList {
            max-height: 510px;
            overflow-y: auto;
        }
    }
</style>
