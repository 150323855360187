import { render, staticRenderFns } from "./todayShift.vue?vue&type=template&id=a08638d2&scoped=true"
import script from "./todayShift.vue?vue&type=script&lang=js"
export * from "./todayShift.vue?vue&type=script&lang=js"
import style0 from "./todayShift.vue?vue&type=style&index=0&id=a08638d2&prod&lang=scss&scoped=plannedShift"


/* normalize component */
import normalizer from "!../../../../../node_modules/.store/vue-loader@15.11.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a08638d2",
  null
  
)

export default component.exports