<template>
    <el-dialog title="当前选择场站详细信息" :visible.sync="visible" width="40%">
        <div class="content">
            <p>场站名称: {{ depot.cName }}</p>
            <p>场站类型: {{ depot.sType }}</p>
            <p>场站规模: {{ depot.type == "undefind"?'':(depot.type= 0?"主场站":"副场站") }}</p>
        </div>
    </el-dialog>
</template>

<script>
    // 展示设备状态
    export default {
        name: "depot",
        data() {
            return {
                visible: false,
                depot: {}
            };
        },
        methods: {
            async init(option) {
                this.visible = true;
                let param = {
                    stationId: option.depotId
                }
                this.$http.post("/station/queryStationInfo", param).then(res => {
                    console.log(res.detail)
                    this.depot = res.detail.stationInfo;
                    this.depot.sType = this.getStationType(this.depot.sType)
                });
            },
            getStationType(val) {
                switch (val) {
                    case "0":
                        return "停车场";
                    case "1":
                        return "维修场站";
                    case "2":
                        return "充电场站";
                    case "3":
                        return "加油场站";
                }
            }
        }
    };
</script>

<style lang="scss" scoped="content">
.content {
    display: flex;
    justify-content: space-around;
}
</style>
