<template>
    <el-dialog  :visible.sync="visible" width="40%" :modal-append-to-body="false">
        <div class="info-wrapper">
            <div class="content">
                <p><span class="title">站点名称: </span> {{ diaform.cName }}</p>
                <p><span class="title">经停线路: </span> {{ diaform.line }}</p>
            </div>
        </div>
        <div>
            <jy-query class="query" ref="checkForm" :model="diaform">
                <jy-query-item label="开始时间" prop="startTime" :span="7">
                    <el-date-picker
                        v-model="diaform.startTime"
                        value-format="yyyy-MM-dd HH:mm"
                        :picker-options="startTime"
                        type="datetime"
                        placeholder="选择时间"
                        popper-class="no-atTheMoment"
                    ></el-date-picker>
                </jy-query-item>
                <jy-query-item label="结束时间" prop="endTime" :span="7">
                    <el-date-picker
                        v-model="diaform.endTime"
                        value-format="yyyy-MM-dd HH:mm"
                        :picker-options="endTime"
                        type="datetime"
                        placeholder="选择时间"
                        popper-class="no-atTheMoment"
                    ></el-date-picker>
                </jy-query-item>
                <template slot="rightCol">
                    <el-button type="primary" v-on:click="search" >站点回放</el-button>
                </template>
            </jy-query>
        </div>
    </el-dialog>
</template>

<script>
    // 展示设备状态
    export default {
        name: "depot",
        data() {
            return {
                visible: false,
                diaform: {
                    startTime: "",
                    endTime: "",
                    cName: "",
                    line: "901路",
                    siteId: ""
                },
                startTime: {
                    disabledDate: (time) => {
                    if (this.diaform.endTime) {
                        return (
                        time.getTime() >=
                        new Date(this.diaform.endTime).getTime() - 8.64e7
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                    },
                },
            };
        },
        computed: {
            endTime() {
            let hour = "";
            if (this.diaform.startTime != "" && this.diaform.startTime != null) {
                hour = new Date(this.diaform.startTime).getHours();
            }
            return {
                disabledDate: (time) => {
                    if (this.diaform.startTime != "" && this.diaform.startTime != null) {
                        if (hour == 0) {
                            return (
                                time.getTime() > new Date(this.diaform.startTime).getTime() ||
                                time.getTime() <
                                new Date(this.diaform.startTime).getTime() - 8.64e7 + 3600000
                            );
                        } else {
                            return (
                                (time.getTime() > new Date(this.diaform.startTime).getTime()) |
                                (time.getTime() <
                                new Date(this.diaform.startTime).getTime() - 8.64e7)
                            );
                        }
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            };
            },
        },
        methods: {
            init(option) {
                this.visible = true;
                console.log(option)
                this.diaform.cName = option.siteInfo.cName
                this.diaform.siteId = option.siteInfo.siteId
            },
            search() {
                this.$router.push({ name: "siteTrack" });
            }
        }
    };
</script>

<style lang="scss" scoped="info-wrapper">
    .info-wrapper {
        display: flex;
        flex-direction: column;
        .content {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            padding-left: 10px;
            .title {
                font-weight: bold;
                color: #000;
            }
            >p{
                padding: 10px;
            }
        }
    }
</style>
