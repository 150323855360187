<template>
    <div>
        <div class="track-map" ref="trackContainer"></div>

        <div class="track-control">
            <el-button :disabled="isRunning" type="primary" size="small" @click="start">开始</el-button>

            <el-button :disabled="!isRunning" type="primary" size="small" @click="pause">暂停</el-button>

            <el-button :disabled="!isRunning" type="primary" size="small" @click="stop">结束</el-button>
        </div>
    </div>
</template>

<script>
    import gcoord from "gcoord";
    import LuShu from "@/common/js/BMapLib/LuShu.js";
    import startImage from "@/assets/startIcon.png";
    import endImage from "@/assets/endIcon.png";

    let trackMap = null,
        lushu = null;
    export default {
        mounted() {
            this.$nextTick(() => {
                this.initMap();
            });
        },
        destoryed() {
            if (this.isRunning) {
                this.stop();
            }
            trackMap.clearOverlays();
            trackMap = null;
            lushu = null;
            gpsData = [];
        },
        methods: {
            init(list, options) {
                this.routeId = options.orgId;
                this.clientId = options.clientId;
                this.getTrack();
            },
            initMap() {
                const dom = this.$refs.trackContainer;
                trackMap = new BMap.Map(dom);
                trackMap.enableScrollWheelZoom();
                trackMap.centerAndZoom(new BMap.Point(116.404, 39.915), 13);
            },
            start() {
                lushu.start();
                this.isRunning = true;
            },
            pause() {
                lushu.pause();
                this.isRunning = false;
            },
            stop() {
                lushu.stop();
                this.isRunning = false;
            },
            getTrack() {
                let url = "/waybillActual/getGpsDataByDevice";
                this.$http
                    .post(url, {
                        deviceNo: this.clientId,
                        routeId: this.routeId,
                        beginTime: new Date().Format("yyyy-MM-dd") + " 00:00:00",
                        endTime: new Date().Format("yyyy-MM-dd hh:mm:ss")
                    })
                    .then(res => {
                        const arrPois = res.detail.map(v => {
                            const [lng, lat] = gcoord.transform([v.lng, v.lat], gcoord.WGS84, gcoord.BD09);
                            return new BMap.Point(lng, lat);
                        });
                        this.getPolyline(arrPois);
                        trackMap.setViewport(arrPois);

                        //   添加标注
                        if (arrPois.length) {
                            let startPoint = new window.BMap.Point(arrPois[0].lng, arrPois[0].lat);
                            let endPoint = new window.BMap.Point(arrPois[arrPois.length - 1].lng, arrPois[arrPois.length - 1].lat);
                            var startIcon = new BMap.Icon(startImage, new BMap.Size(50, 50));
                            var endIcon = new BMap.Icon(endImage, new BMap.Size(50, 50));
                            let marker = new BMap.Marker(startPoint, {
                                icon: startIcon
                            });
                            let endmarker = new BMap.Marker(endPoint, {
                                icon: endIcon
                            });
                            trackMap.addOverlay(marker);
                            trackMap.addOverlay(endmarker);

                            trackMap.setCenter(startPoint);
                            trackMap.setZoom(14);
                        }

                        this.getLushu(arrPois);
                    });
            },
            // 创建轨迹
            getPolyline(trackList) {
                let tempList = [trackList[0]];
                for (let index = 1; index < trackList.length; index++) {
                    const f = trackList[index - 1];
                    const l = trackList[index];
                    if (BMapLib.GeoUtils.getDistance(f, l) <= 300) {
                        tempList.push(l);
                    } else {
                        // 绘制
                        const polyline = new BMap.Polyline(tempList, {
                            strokeColor: "blue", //设置颜色
                            strokeWeight: 5, //宽度
                            strokeOpacity: 0.5 //透明度
                        });
                        trackMap.addOverlay(polyline);
                        tempList = [l];
                    }
                }
                if (tempList.length > 1) {
                    const polyline = new BMap.Polyline(tempList, {
                        strokeColor: "blue", //设置颜色
                        strokeWeight: 5, //宽度
                        strokeOpacity: 0.5 //透明度
                    });
                    trackMap.addOverlay(polyline);
                }
            },
            // 创建路书
            getLushu(arrPois) {
                lushu = new LuShu(trackMap, arrPois, {
                    defaultContent: "", //"从天安门到百度大厦"
                    autoView: true, //是否开启自动视野调整，如果开启那么路书在运动过程中会根据视野自动调整
                    icon: new BMap.Icon("https://developer.baidu.com/map/jsdemo/img/car.png", new BMap.Size(52, 26), { anchor: new BMap.Size(27, 13) }),
                    speed: 200, //运行速度
                    enableRotation: true, //是否设置marker随着道路的走向进行旋转
                    landmarkPois: [
                        {
                            lng: 116.314782,
                            lat: 39.913508,
                            html: "加油站",
                            pauseTime: 2
                        }
                    ]
                });
            }
        },
        data() {
            return {
                routeId: "",
                clientId: "",

                isRunning: false
            };
        }
    };
</script>

<style lang="scss" scoped>
    .track-map {
        height: 480px;
    }
    .track-control {
        margin-top: 10px;
        text-align: center;
    }
</style>
