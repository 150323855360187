<template>
    <div class="road_info_management">
        <jy-table ref="jyTable" :data="dataList"  class="table">
            <jy-table-column prop="routeNa" label="线路名称" width="100" ></jy-table-column>
            <jy-table-column prop="rType" label="线路类型" min-width="100" ></jy-table-column>
            <jy-table-column prop="upStartSite" label="上行起点站" width="95"></jy-table-column>
            <jy-table-column prop="upEndSite" label="上行终点站" width="95" ></jy-table-column>
            <jy-table-column prop="upNum" label="上行站点数" width="80" ></jy-table-column>
            <jy-table-column prop="downStartSite" label="下行起点站" width="135" ></jy-table-column>
            <jy-table-column prop="downEndtSite" label="下行终点站" width="135" ></jy-table-column>
            <jy-table-column prop="downNum" label="下行站点数" width="80"></jy-table-column>
            <jy-table-column prop="routeLen" label="线路里程" width="80"></jy-table-column>
            <jy-table-column prop="statu" label="状态(km)" width="80"></jy-table-column>
        </jy-table>
        <div class="total">
            <span>线路数：10条</span>
            <span>正常运营：10条</span>
            <span>停运：0条</span>
            <span>线路里程：200km</span>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            btnMenuId: 'scheduleRoadBillInfoManagement',
            butList: [],
            dataList: [],
            pageIndex: 1,
            pageSize: 10,
            total: 0,
            formData: {
                oDate: '',
                routeIds: [],
                customNo: '',
                vehicleNo: '',
                driverNa: '',
                eState: '',
                verify: '',
            },
            routeList: [],
            routeInfo: ''
        }
    },
    filters: {
        dFromLine (val) {
            if (val == '0') {
                return '正常'
            } else if (val == '1') {
                return '偏离'
            }
        },
    },
    created() {
    },
    methods: {
        init(options) {
            this.routeInfo = options
            this.getList()
        },
        getList() {
            this.dataList = [{
                routeNa:"901",
                rType: "上下行",
                upStartSite:"湖心小区",
                upEndSite: "汽车东站",
                upNum: 30,
                downStartSite:"汽车东站",
                downEndtSite:"湖心小区",
                downNum: 30,
                routeLen: "20km",
                statu: "正常"
            },{
                routeNa:"902",
                rType: "上下行",
                upStartSite:"湖心小区",
                upEndSite: "汽车东站",
                upNum: 30,
                downStartSite:"汽车东站",
                downEndtSite:"湖心小区",
                downNum: 30,
                routeLen: "20km",
                statu: "正常"
            },{
                routeNa:"903",
                rType: "上下行",
                upStartSite:"湖心小区",
                upEndSite: "汽车东站",
                upNum: 30,
                downStartSite:"汽车东站",
                downEndtSite:"湖心小区",
                downNum: 30,
                routeLen: "20km",
                statu: "正常"
            },{
                routeNa:"904",
                rType: "上下行",
                upStartSite:"湖心小区",
                upEndSite: "汽车东站",
                upNum: 30,
                downStartSite:"汽车东站",
                downEndtSite:"湖心小区",
                downNum: 30,
                routeLen: "20km",
                statu: "正常"
            }]
            console.log(this.dataList)
        },
    }
}

</script>
<style lang="scss" scoped="road_info_management">
.road_info_management {
    max-height: 680px;
    .query {
        margin-top:10px;
    }
    .table {
        font-size: 12px;
    }
    .total {
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 54px;
        background: inherit;
        // background-color: rgba(215, 215, 215, 1);
        padding: 20px;
        font-size: initial;
    }
}
</style>
