import DVI4 from "./plugins/DVI4";
import * as G711 from "alawmulaw";
import MP3 from "./plugins/MP3";
/**
 * 音频编解码器
 * 暂时支持g711a g711u mp3 lpcm adpcm_dvi4格式
 */

class AudioFormat {
  constructor(option) {
    if (!option) {
      throw new Error("未传入option");
    }
    this.format = option.formatType || 6;
    this.hisi = option.hisi || false;
    this.byteLength = option.byteLength || 640;
    this.encodeLength = option.encodeLength || 640;

    if (this.format == 25) {
      this.mp3 = new MP3();
    } else if (this.format == 26) {
      this.div4 = new DVI4();
    }

    // 上传的音频数据缓存
    this.cacheUp = null;
  }

  /**
   *
   * @param {Uint8Array} data
   * @returns {Array<Uint8Array>} 解码后的数据
   */
  putUp(data) {
    if (data && data.length && this._isUint8Array(data)) {
      const cacheLength = (this.cacheUp && this.cacheUp.length) || 0;
      const dlen = cacheLength + data.length;
      const end = data.length - (dlen % this.encodeLength);

      let encodeArr = [];
      if (cacheLength) {
        const uint8 = new Uint8Array(cacheLength + end);
        uint8.set(this.cacheUp);
        uint8.set(data.subarray(0, end), cacheLength);
        encodeArr = this._encode(uint8);
      } else {
        const uint8 = new Uint8Array(end);
        uint8.set(data.subarray(0, end), cacheLength);
        encodeArr = this._encode(uint8);
      }

      const restUint8 = new Uint8Array(dlen % this.encodeLength);
      restUint8.set(data.subarray(end));
      this.cacheUp = restUint8;

      return encodeArr;
    }
  }

  /**
   *
   * @param {Uint8Array} data
   * @returns
   */
  putDown(data) {
    return this._decodeBlock(data);
  }

  _isUint8Array(data) {
    return data.byteLength && data.buffer && data.constructor == Uint8Array;
  }

  /**
   * @param { Uint8Array } data
   * @returns { Array }
   */
  _encode(data) {
    const n = Math.floor(data.length / this.encodeLength);
    let encodeArr = [];
    for (let index = 0; index < n; index++) {
      const u8s = new Uint8Array(this.encodeLength);
      u8s.set(
        data.subarray(
          index * this.encodeLength,
          (index + 1) * this.encodeLength
        )
      );
      encodeArr.push(this._encodeBlock(new Int16Array(u8s.buffer)));
    }
    return encodeArr;
  }

  /**
   *
   * @param {Int16Array} data
   * @returns {Uint8Array}
   */
  _encodeBlock(data) {
    switch (this.format) {
      case 6: {
        return G711.alaw.encode(data);
      }
      case 7: {
        return G711.mulaw.encode(data);
      }
      case 18: {
        return data;
      }
      case 25: {
        return this.mp3.encode(data);
      }
      case 26: {
        return new Uint8Array(this.div4.fromPcm(data, true));
      }
    }
  }

  /**
   *
   * @param {Uint8Array} data
   * @returns {Int16Array}
   */
  _decodeBlock(data) {
    switch (this.format) {
      case 6: {
        return G711.alaw.decode(data);
      }
      case 7: {
        return G711.mulaw.decode(data);
      }
      case 18: {
        return new Int16Array(data.buffer);
      }
      case 25: {
        return this.mp3.decode(data);
      }
      case 26: {
        return this.div4.toPcm(data);
      }
    }
  }

  destroy() {
    this.cacheUp = null;

    if (this.mp3) {
      this.mp3.destroy();
      this.mp3 = null;
    }
    this.div4 = null;
  }
}

export default AudioFormat;
