export default class LinkedPool {
    constructor() {
        this.pool = new Map();

        this.relation = [];

        this.callback = function() {};
    }

    on(callback) {
        if (typeof callback === "function") {
            this.callback = callback;
        }
    }

    _connect(ip, port, context, clientId) {
        if (!this.pool.has(ip + port)) {
            const socket = new WebSocket(`ws://${ip}:${port}/${context}`);
            socket.onclose = function() {
                console.log("close");
            };
            const that = this;
            socket.onmessage = function(event) {
                const response = JSON.parse(event.data);
                if (response.title === "pushSubscribe") {
                    that.callback.call(that, JSON.parse(response.content));
                }
            };
            socket.onopen = function() {
                socket.send(
                    JSON.stringify({
                        title: "subscribe",
                        content: `{"topic":"deviceRtData","ids":["${clientId}"]}`,
                        time: new Date().Format("yyyy-MM-dd hh:mm:ss")
                    })
                );
            };

            this.pool.set(ip + port, {
                channel: socket,
                count: 1
            });
        } else {
            const ws = this.pool.get(ip + port);
            ws.count += 1;
            ws.channel.send(
                JSON.stringify({
                    title: "subscribe",
                    content: `{"topic":"deviceRtData","ids":["${clientId}"]}`,
                    time: new Date().Format("yyyy-MM-dd hh:mm:ss")
                })
            );
        }

        const one = this.relation.find(v => v.clientId === clientId);
        if (!one) {
            this.relation.push({
                ip,
                port,
                context,
                clientId
            });
        }
    }

    _disconnect(ip, port) {
        if (this.pool.has(ip + port)) {
            const ws = this.pool.get(ip + port);
            ws.channel.close();
            this.pool.delete(ip + port);
        }
    }

    add(d) {
        this._connect(d.ip, d.pot, d.context, d.clientId);
    }

    delete(clientId) {
        const dIndex = this.relation.findIndex(v => v.clientId === clientId);
        if (dIndex !== -1) {
            const d = this.relation.splice(dIndex, 1);
            const [{ ip, port }] = d;
            const ws = this.pool.get(ip + port);
            if (ws) {
                ws.count--;
                // 全部取消
                ws.channel.send(JSON.stringify({ title: "unSubscribe", time: new Date().Format("yyyy-MM-dd hh:mm:ss") }));

                if (ws.count === 0) {
                    this._disconnect(ip, port);
                } else {
                    const list = this.relation.filter(v => v.ip + v.port === ip + port);
                    ws.channel.send(
                        JSON.stringify({
                            title: "subscribe",
                            content: `{"topic":"deviceRtData","ids":${JSON.stringify(list.map(v => v.clientId))}}`,
                            time: new Date().Format("yyyy-MM-dd hh:mm:ss")
                        })
                    );
                }
            }
        }
    }
}
