// http-flv连接地址
export const liveBaseUrl = window.location.origin + "/video/";

// 语音对讲websocket
let wsHeader = "ws://";
if (window.location.protocol == "https:") {
    wsHeader = "wss://";
} else {
    wsHeader = "ws://";
}
export const talkWSUrl = wsHeader + window.location.host + "/ws/";

// 流媒体服务器接口
export const streamServerBaseUrl = "/IntelligentArrangeSystem/video";
// export const streamServerBaseUrl = "/videoEQP";

// 系统名称，记录对讲状态
export const system = "dispatch";
