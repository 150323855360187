/**
 * indexDB
 */
import Http from "@/common/js/http";

export default class PreTrackDB {
    constructor() {
        const request = window.indexedDB.open("dispatch-system", 1);
        const that = this;
        request.onupgradeneeded = function(ev) {
            var db = ev.target.result;
            db.createObjectStore("PreTrack", { keyPath: "routeId" });
        };
        request.onsuccess = function(ev) {
            that.db = ev.target.result;
        };
        request.onerror = function(ev) {
            console.log(ev);
        };
    }
    add(routeId, record) {
        const nRecord = {
            routeId,
            record
        };
        const transaction = this.db.transaction(["PreTrack"], "readwrite");

        const objectStore = transaction.objectStore("PreTrack");

        const result = objectStore.add(nRecord);
        return new Promise((resolve, reject) => {
            result.onsuccess = function(event) {
                resolve(event);
            };
            result.onerror = function() {
                reject(id + "记录添加失败");
            };
        });
    }

    delete(id) {
        const transaction = this.db.transaction(["PreTrack"], "readwrite");
        const objectStore = transaction.objectStore("PreTrack");
        objectStore.delete(id);
    }

    update(id) {
        const transaction = this.db.transaction(["PreTrack"]);
        const objectStore = transaction.objectStore("PreTrack");
        return new Promise(resolve => {
            let url = "/route/info/queryRoutePreSetSite";
            let option = {
                routeId: id
            };
            Http.post(url, option).then(({ detail }) => {
                objectStore.put({
                    routeId: id,
                    record: detail
                });
                resolve(detail);
            });
        });
    }

    read(id) {
        const transaction = this.db.transaction(["PreTrack"]);
        const objectStore = transaction.objectStore("PreTrack");
        const queryResult = objectStore.get(id);
        return new Promise((resolve, reject) => {
            queryResult.onsuccess = event => {
                const query = event.target.result;
                if (query) {
                    resolve(query.record);
                } else {
                    let url = "/route/info/queryRoutePreSetSite";
                    let option = {
                        routeId: id
                    };
                    Http.post(url, option).then(({ detail }) => {
                        this.add(id, detail);
                        resolve(detail);
                    });
                }
            };
            queryResult.onerror = () => {
                reject("读取失败");
            };
        });
    }
}
