<template>
    <div class="sendInfo">
        <el-divider></el-divider>
        <div class="txtInfo">
            <el-input type="textarea" :rows="4" placeholder="请输入下发文本" v-model="diaObj.message"></el-input>
        </div>
        <div class="send">
            <el-button size="mini" type="primary" @click="send">发送文本</el-button>
        </div>
        <el-divider></el-divider>
    </div>
</template>
<script>
     export default {
        data() {
            return {
                diaObj: {
                    vehicleId: "",
                    message: ""
                },
            }
        },
        methods: {
            init(vehicleId) {
                this.diaObj.vehicleId = vehicleId
            },
            send() {
                if (!this.diaObj.message) {
                    this.$message({
                        type: "warning",
                        message: "请输入文本内容"
                    });
                    return false;
                }
                let url = "/waybillPlan/textSend";
                let option = {
                    ...this.diaObj
                };
                this.$http.post(url, option).then(() => {
                    this.dialogVisible = false;
                    this.getlist();
                    this.$message({
                        type: "success",
                        message: "下发成功!"
                    });
                });
            },
        }
     }
</script>
<style lang="scss" scoped="sendInfo">
    .txtInfo {
        margin-top: 10px;
        padding: 10px;
    }
    .send {
        text-align: center;
        margin-top: 20px;
    }
</style>
