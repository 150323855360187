import { talkWSUrl, streamServerBaseUrl } from "./config";
import { Base64 } from "js-base64";
import PcmPlayer from "pcm-player-ex";
import Axios from "axios";
import Recorder from "recorder-realtime";
import AudioFormat from "./AudioFormat";
import AudioFormatDetect from "./AudioFormatDetect";
import Util from "@/common/js/util.js";

class WebRecorder {
    constructor(object) {
        // 录音状态
        this.isRecord = false;

        this.deviceId = object.deviceId || object.clientId;
        this.ch = object.channelId;
        // 初始化
        this.ws = null;
        // pcm
        this.pcmPlayer = new PcmPlayer({
            inputCodec: "Int16",
            channels: 1,
            sampleRate: 8000,
            flushingTime: 3000
        });

        // 音频嗅探
        this.detect = new AudioFormatDetect();
        this.hasDetect = false;
        // 音频编解码工具
        this.audioFormat = null;
        // 初始化recorder
        this.initRecord();
    }

    setDeviceId(id) {
        this.deviceId = id;
    }

    setChannelId(id) {
        this.ch = id;
    }

    setHost(ip, port) {
        this.hostIP = ip;
        this.hostPort = port;
    }

    initRecord() {
        if (!Recorder.isRecordingSupported()) {
            console.log("Recording features are not supported in your browser.");
        } else {
            this.recorder = new Recorder({
                numberOfChannels: 1, // this is suggested
                wavBitDepth: 16,
                wavSampleRate: 8000,
                streamPages: true // // we can get the buffer stream in realtime in the ondataavailable callback
            });
            this.recorder.onstart = () => {
                console.log("recorder started");
            };
            this.recorder.onstreamerror = e => {
                console.log(e);
            };
            const vm = this;
            this.recorder.ondataavailable = data => {
                if (data.command === "buffer") {
                    if (vm.ws && vm.ws.readyState == 1 && vm.audioFormat) {
                        const encodeArr = vm.audioFormat.putUp(data.buffer);
                        encodeArr.forEach(v => {
                            if (vm.ws && vm.ws.readyState == 1 && v && v.length) {
                                vm.ws.send(
                                    JSON.stringify({
                                        type: "audio",
                                        format: vm.detect && vm.detect.formatType,
                                        ch: vm.ch,
                                        deviceId: vm.deviceId,
                                        content: Base64.fromUint8Array(v)
                                    })
                                );
                            }
                        });
                    }
                }
            };
        }
    }

    static isSupported() {
        return Recorder.isRecordingSupported();
    }

    begin() {
        this.initWebSocket();
    }

    stop() {
        if (this.ws) {
            this.ws.close();
            this.ws = null;
        }

        this.recorder && this.recorder.stop();
        this.isRecord = false;
        this.cacheData = [];
    }

    // 下发打开语音对讲指令
    sendOpenDirective() {
        Axios.get("/realtimeMediaUploadReq", {
            baseURL: streamServerBaseUrl,
            params: {
                hostIP: this.hostIP,
                hostPort: this.hostPort,
                codeType: "1",
                mediaType: "2",
                clientId: this.deviceId,
                channelId: this.ch
            },
            headers: {
                token: Util.getStor("token")
            }
        })
            .then(() => {
                this.recorder && this.recorder.start();
                this.isRecord = true;
            })
            .catch(() => {
                this.stop();
            });
    }

    initWebSocket() {
        this.ws = new WebSocket(talkWSUrl);
        this.ws.binaryType = "arraybuffer"; //传输的是 ArrayBuffer 类型的数据
        const vm = this;
        this.ws.onopen = function() {
            if (vm.ws.readyState == 1) {
                //ws进入连接状态，则每隔500毫秒发送一包数据
                console.log("连接成功");
                vm.ws.send(
                    JSON.stringify({
                        type: "reg",
                        deviceId: vm.deviceId,
                        ch: vm.ch
                    })
                );
                vm.sendOpenDirective();
            }
        };
        this.ws.onmessage = function(msg) {
            if (!vm.hasDetect) {
                // 第一次嗅探格式
                vm.detect.detect(msg.data);
                // 将嗅探结果传入编解码器
                vm.audioFormat = new AudioFormat(vm.detect);
                vm.hasDetect = true;
            } else {
                const message = JSON.parse(msg.data);
                const uint8 = vm.audioFormat.putDown(Base64.toUint8Array(message.content));
                if (uint8 && uint8.length) {
                    vm.pcmPlayer.feed(new Int8Array(uint8.buffer));
                }
            }
        };
        this.ws.onclose = function() {
            console.log("close");
        };
        this.ws.onerror = function(err) {
            console.error(err);
        };
    }

    destrory() {
        this.stop();
        if (this.pcmPlayer) {
            this.pcmPlayer.destroy();
            this.pcmPlayer = null;
        }
        if (this.audioFormat) {
            this.audioFormat.destroy();
            this.audioFormat = null;
        }
        this.detect = null;
    }
}

export default WebRecorder;
