<template>
    <div class="serverTotalBox">
        <el-tabs type="border-card">
            <el-tab-pane label="线路统计" class="tab"><route-totals ref="route"></route-totals></el-tab-pane>
            <el-tab-pane label="车辆统计" class="tab"><vehicles-totals  ref="vehicles"></vehicles-totals></el-tab-pane>
            <el-tab-pane label="司机统计" class="tab"><driver-totals ref="driver"></driver-totals></el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import routeTotals from "./components/routeTotals.vue";
import vehiclesTotals from "./components/vehiclesTotals.vue";
import driverTotals from "./components/driverTotals.vue";
export default {
    data() {
        return {
            options: ''
        }
    },
    components: {
        routeTotals,
        vehiclesTotals,
        driverTotals
    },
    methods: {
        init(options) {
            this.options = options
            this.$refs.route.init(options)
            this.$refs.vehicles.init(options)
            this.$refs.driver.init(options)
        },
    }

}

</script>
<style lang="scss" scoped="serverTotalBox">
.serverTotalBox {
    overflow-y: auto;
    .tab {
        overflow-x: auto;
    }
}
</style>
