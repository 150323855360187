<template>
	<div class="signal_box">
		<div class="signal_circle signal_first" :class="{ 'signal_active': signal >= 1 }"></div>
		<div class="signal_circle signal_second" :class="{ 'signal_active': signal >= 2 }"></div>
		<div class="signal_circle signal_third" :class="{ 'signal_active': signal >= 3 }"></div>
	</div>
</template>

<script>
	export default {
		props: {
			signal: {
				type: [Number, String],
				default: 0,
			},
		},
	};
</script>
<style lang="scss" scoped>
	.signal_box {
		display: inline-block;
		transform: rotate(45deg);
		width: 30px;
		height: 30px;
		overflow: hidden;
		position: relative;
		.signal_circle {
			border-radius: 50%;
			position: absolute;
			border: 4px solid #d9d9d9;
		}
		.signal_active {
			border-color: #3879ff;
		}
		.signal_first {
			width: 6px;
			top: 24px;
			left: 24px;
		}

		.signal_second {
			width: 24px;
			height: 24px;
			top: 16px;
			left: 16px;
		}

		.signal_third {
			width: 36px;
			height: 36px;
			top: 9px;
			left: 9px;
		}
	}
</style>