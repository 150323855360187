/**
 * 音频格式嗅探
 */
import { Base64 } from "js-base64";

class AudioFormatDetect {
  constructor() {
    this.formatType = 26;

    this.hisi = false;

    // 长度统一按照Uint8Array来计算
    this.byteLength = 640;

    this.encodeLength = 640;
  }

  detect(jsonStr) {
    const json = JSON.parse(jsonStr);
    this.formatType = json.format;

    const audioBuffer = Base64.toUint8Array(json.content);
    this._detectHiSi(audioBuffer);

    this.byteLength = audioBuffer.length;

    this.encodeLength = audioBuffer.length;

    this._detectEncodeLength();
  }

  /**
   * 都按照Uint8Array计算压缩比例
   */
  _detectEncodeLength() {
    if (this.formatType == 6 || this.formatType == 7) {
      // g711 压缩比率为2:1，即当bytelength为640时，data的length为1280
      if (this.hisi) {
        this.encodeLength = (this.byteLength - 4) * 2;
      } else {
        this.encodeLength = this.byteLength * 2;
      }
    } else if (this.formatType == 26) {
      // adpcm 压缩比率为4:1，当bytelength为168时，头部固定占8个字节，data的length为640
      if (this.hisi) {
        this.encodeLength = (this.byteLength - 8) * 4;
      } else {
        this.encodeLength = (this.byteLength - 4) * 4;
      }
    } else if (this.formatType == 25) {
      // mp3 还需调整 默认10:1
      if (this.hisi) {
        this.encodeLength = (this.byteLength - 4) * 10;
      } else {
        this.encodeLength = this.byteLength * 10;
      }
    }
  }

  _detectHiSi(data) {
    if (
      data[0] == 0x00 &&
      data[1] == 0x01 &&
      (data[2] & 0xff) == (data.length - 4) / 2 &&
      data[3] == 0x00
    ) {
      this.hisi = true;
    } else {
      this.hisi = false;
    }
  }
}

export default AudioFormatDetect;
