<template>
    <transition name="rightAside" v-on:after-enter="afterEnter">
        <div v-if="visible" class="ra-wrapper">
            <div class="ra-nav">
                <div class="ra-nav-item" :class="{ 'is-active': active === '1' }" @click="tabClick('1')">
                    <img :src="active === '1' ? icons.icon_basic_select : icons.icon_basic" />
                    <span class="tab_label_title">基础信息</span>
                </div>
                <div class="ra-nav-item" :class="{ 'is-active': active === '2' }" @click="tabClick('2')">
                    <img :src="active === '2' ? icons.icon_todayShift_select : icons.icon_todayShift" />
                    <span class="tab_label_title">今日排班</span>
                </div>
                <div class="ra-nav-item" :class="{ 'is-active': active === '3' }" @click="tabClick('3')">
                    <img :src="active === '3' ? icons.icon_attendance_detail_select : icons.icon_attendance_detail" />
                    <span class="tab_label_title">考勤明细</span>
                </div>
                <div class="ra-nav-item" :class="{ 'is-active': active === '6' }" @click="tabClick('6')">
                    <img :src="active === '6' ? icons.icon_roadBill_select : icons.icon_roadBill" />
                    <span class="tab_label_title">路单明细</span>
                </div>
                <div class="ra-nav-item" :class="{ 'is-active': active === '7' }" @click="tabClick('7')">
                    <img :src="active === '7' ? icons.icon_serviceTotal_select : icons.icon_serviceTotal" />
                    <span class="tab_label_title">运营统计</span>
                </div>
            </div>
            <div class="ra-content">
                <basic ref="basic" key="1" v-show="active === '1'"></basic>
                <today-shift ref="shift" key="2" v-show="active === '2'"></today-shift>
                <attendance-detail ref="route" key="3" v-show="active === '3'"></attendance-detail>
                <road-bill ref="bill"  key="6" v-show="active === '6'"></road-bill>
                <serve-total ref="servetotal"  key="7" v-show="active === '7'"></serve-total>
            </div>

            <div size="mini" class="button_close" @click="close"><i class="el-icon-close"></i></div>
        </div>
    </transition>
</template>

<script>
    import basic_normal from "@/assets/serviceMap/rightAside/basic_normal.svg";
    import basic_select from "@/assets/serviceMap/rightAside/basic_selected.svg";
    import todayShift_select from "@/assets/serviceMap/rightAside/todayShift.svg";
    import todayShift_normal from "@/assets/serviceMap/rightAside/todayShift_out.svg";
    import roadBill_select from "@/assets/serviceMap/rightAside/roadBill.svg";
    import roadBill_normal from "@/assets/serviceMap/rightAside/roadBill_out.svg";
    import serviceTotal_select from "@/assets/serviceMap/rightAside/serviceTotal.svg";
    import serviceTotal_normal from "@/assets/serviceMap/rightAside/serviceTotal_out.svg";
    import route_detail_normal from "@/assets/serviceMap/rightAside/route_detail_out.svg";
    import route_detail_select from "@/assets/serviceMap/rightAside/route_detail.svg";
    import attendance_normal from "@/assets/serviceMap/rightAside/attendance_out.svg";
    import attendance_select from "@/assets/serviceMap/rightAside/attendance.svg";

    import Basic from "./Basic.vue";
    import todayShift from "./todayShift.vue";
    import roadBill from "./roadBill.vue";
    import serveTotal from "./serverTotal.vue";
    import attendanceDetail from "./attendanceDetail.vue";
    export default {
        destroyed() {
            this.visible = false;
            this.active = "1";
        },
        methods: {
            afterEnter() {},
            close() {
                this.visible = false;
                this.active = "1";
            },

            init(options,checked) {
                if(checked) {
                    this.visible = true;
                    this.active = "1";
                    this.$nextTick(() => {
                        this.$refs.basic.init(options);
                        this.$refs.shift.init(options);
                        this.$refs.bill.init(options);
                        this.$refs.servetotal.init(options);
                        this.$refs.route.init(options);
                    });
                } else {
                    this.visible = false;
                }
            },
            tabClick(item) {
                this.active = item;
            },
        },
        data() {
            return {
                visible: false,
                vehicleId: "",
                vehicleNo: "",

                active: "1",
                deviceIds: "",

                typeList: [],
                icons: {
                    icon_basic: basic_normal,
                    icon_basic_select: basic_select,
                    icon_todayShift: todayShift_normal,
                    icon_todayShift_select: todayShift_select,
                    icon_roadBill: roadBill_normal,
                    icon_roadBill_select: roadBill_select,
                    icon_serviceTotal: serviceTotal_normal,
                    icon_serviceTotal_select: serviceTotal_select,
                    icon_route_detail: route_detail_normal,
                    icon_route_detail_select: route_detail_select,
                    icon_attendance_detail: attendance_normal,
                    icon_attendance_detail_select: attendance_select,
                }
            };
        },
        components: {
            Basic,
            todayShift,
            roadBill,
            serveTotal,
            attendanceDetail
        }
    };
</script>
<style lang="scss" scoped="ra-wrapper">
    .rightAside-enter,
    .rightAside-leave-to {
        opacity: 0;
        transform: translateX(550px);
    }

    .rightAside-enter-active,
    .rightAside-leave-active {
        transition: all 0.5s ease;
    }

    .content-enter,
    .content-leave-to {
        opacity: 0;
    }

    .content-enter-active,
    .content-leave-active {
        transition: opacity 0.2s ease;
    }
    .ra-wrapper {
        position: absolute;
        right: 50px;
        top: 50px;
        width: 850px;
        background-color: white;
        display: flex;
        & > .ra-nav {
            display: flex;
            flex-direction: column;
            width: 80px;
            .ra-nav-item {
                display: flex;
                flex-direction: column;
                padding-top: 16px;
                width: 80px;
                height: 80px;
                text-align: center;
                color: #c8c8c8;
                cursor: pointer;
                img {
                    width: 36px;
                    height: 30px;
                    margin: 0 auto;
                }
                .tab_label_title {
                    margin-top: 5px;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
            & > .is-active {
                background-color: #3572f2;
                color: #fff;
            }
        }
        & > .ra-content {
            box-shadow: -4px 0 5px #ebebeb;
            width: 770px;
            padding: 10px 15px;
        }

        .button_close {
            position: absolute;
            width: 30px;
            height: 30px;
            top: 0;
            right: -30px;
            background-color: #fff;
            border: 1px solid #ddd;
            cursor: pointer;
            display: table-cell;
            text-align: center;
            & > i {
                font-size: 25px;
            }
            & :hover {
                color: #3572f2;
            }
        }
    }
</style>
