<template>
    <div class="info-wrapper">
        <div class="jy-homeSum">
            <div class="homelayout">
                <div class="homeitem" v-for="(one) in OperationList" v-bind:key="one.id" >
                    <slot :data="one">
                        <div :id="one.name" class="img"></div>
                    </slot>
                    <div class="text">
                        <div class="number">{{one.num}}</div>
                        <div>{{one.info}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as Echarts from "echarts";
    export default {
        data() {
            return {
                intervalObj: null,
                OperationList:[
                    {
						info: "趟次完成率",
						num: "",
                        canClick: false,
                        name:"driver_shiftRate"
					},
                    {
						info: "发车准点率",
						num: "",
                        canClick: false,
                        name:"driver_dePunctualityRate"
					},{
						info: "缺班率",
						num: "30",
                        canClick: false,
                        name:"driver_lackShiftRate"
					}
                ]
            }
        },
        mounted() {
            this.getShiftCompletionRate();
            this.getDePunctualityRate();
            this.getDriverLackShiftRate();
        },
        methods: {
            getoption(val) {
                let Option = {
                    series: [
                        {
                            name: '完成率',
                            type: 'pie',
                            selectedMode: 'single',
                            radius: ['50%', '90%'],
                            labelLine: {
                                show: false
                            },
                            data: [
                                {value: val, name: '完成'+val+'%'},
                                {value: 100-val, name: '未完成'+(100-val)+'%'},
                            ],
                            color: ['#169bd5','#cccccc']
                        }
                    ],
                    graphic: {
                        elements: [
                            {
                                type: 'text',
                                left: 'center',
                                top: '45%',
                                style: {
                                    text: val+'%',
                                    fontSize: 8,
                                    textAlign: 'center',
                                    width: 30,
                                    height: 25,
                                    fill: '#3BA5D9'
                                }
                        }]
                    }
                }
                return Option
            },
            getShiftCompletionRate() {
                let url = "/home/shiftCompletionRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'driver_shiftRate') {
                            item.num = Math.round(detail*100)+'%'
                        }
                    })
                    let shift = Math.round(detail*100)
                    let Option = this.getoption(shift)
                    this.draw("driver_shiftRate",Option)
                });
            },
            getDePunctualityRate() {
                let url = "/home/dePunctualityRate"
                this.$http.post(url).then(({ detail }) => {
                    this.OperationList.forEach(item=>{
                        if(item.name == 'driver_dePunctualityRate') {
                            item.num = Math.round(detail*100)+'%'
                        }
                    })
                    let dePunctualityRate = Math.round(detail*100)
                    let Option = this.getoption(dePunctualityRate)
                    this.draw("driver_dePunctualityRate",Option)
                });
            },
            getDriverLackShiftRate() {
                let Option = this.getoption(30)
                this.draw("driver_lackShiftRate",Option)
            },
            draw(name,Option) {
                let eIns = Echarts.init(document.getElementById(name));
                eIns.clear();
                eIns.setOption(Option,true);
            }
        }
    };
</script>
<style lang="scss" scoped="info-wrapper">
    .info-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 5px;
        .jy-homeSum {
            // box-shadow: #dcdcdc 0px 0px 7px;
            padding: 5px 5px;
            background: #fff;
            .homelayout {
                // display: flex;
                flex: 1;
                flex-wrap: wrap;
                justify-content: space-evenly;
                .homeitem {
                    // flex: 1;
                    border-left: 1px solid #ebebeb;
                    padding-left: 5px;
                    display: inline-flex;
                    &.hover{
                        cursor: pointer;
                    }
                    .img{
                        height: 45px;
                        width: 45px;
                    }
                    .text {
                        padding: 5px;
                        display: flex;
                        flex-direction: column;
                        .number {
                            flex:1;
                            font-weight: bold;
                            font-size: 14px;
                            color:#5A5A5A;
                            + div {
                                color: #B3B8CE;
                                font-size: 8px;
                                font-weight: 600;
                            }
                        }
                    }
                    // + .homeitem {
                    //     border-left: 1px solid #ebebeb;
                    //     padding-left: 5px;
                    // }
                }
            }
        }
    }
</style>
