<template>
    <div class="basic_wrapper">
        <div class="basic_header">
            <img :src="bus_background" />
            <div class="basic_header_right">
                <div class="basic_signal">
                    <signal class="signal" :signal="signal"></signal>
                    <span>网联状态</span>
                </div>
                <div class="basic_speed">
                    <span>{{ speed }}</span>
                    <span>km/h</span>
                </div>
            </div>
        </div>
        <div class="basic_progress">
            <progress-bar :value="soc"></progress-bar>
        </div>
        <div class="basic_vehicle_info">
            <el-descriptions :column="1" size="medium" labelClassName="basic_vehicle_info_label" contentClassName="basic_vehicle_info_content">
                <el-descriptions-item label="车牌号">{{ vehicleNo }}</el-descriptions-item>
                <el-descriptions-item label="车辆编号">{{ customizeNo }}</el-descriptions-item>
                <el-descriptions-item label="所属机构">{{ orgName }}</el-descriptions-item>
                <el-descriptions-item label="位置">{{ address }}</el-descriptions-item>
                <el-descriptions-item label="载客数">{{ pCount }}人</el-descriptions-item>
                <el-descriptions-item label="定位时间">{{ time }}</el-descriptions-item>
            </el-descriptions>
        </div>
        <el-divider></el-divider>
        <div class="basic_driver_info">
            <el-row>
                <el-col :span="14">
                    <el-descriptions :column="1" size="medium" labelClassName="basic_driver_info_label" contentClassName="basic_driver_info_content">
                        <el-descriptions-item label="驾驶员姓名">{{ driverName }}</el-descriptions-item>
                        <el-descriptions-item label="联系电话">{{ phoneNo }}</el-descriptions-item>
                        <el-descriptions-item label="实时心率">
                            <span>{{ heartRate }}次/分</span>
                            <span>正常</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="实时血压">
                            <span>{{ minbp }}/{{ maxbp }}</span>
                            <span>正常</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="综合评分">{{ rankScore }}分({{ getYM }})</el-descriptions-item>
                        <el-descriptions-item label="周期累计计分">{{ countScore }}分</el-descriptions-item>
                    </el-descriptions>
                </el-col>
                <el-col :span="10">
                    <img :src="driver_background" />
                </el-col>
            </el-row>
        </div>
        <div class="detail">
            <span class="title">数据概览</span>
            <el-divider></el-divider>
            <div>
                <div class="detail_item" v-for="(one) in detailList" v-bind:key="one.id" v-bind:class="{'hover':one.canClick===undefined?canClick:one.canClick}" v-on:click="itemClick(one)">
                    <slot :data="one">
                        <img :src="one.icon" class="img"/>
                    </slot>
                    <div class="text">
                        <div class="number1" v-if="one.canClick == true">{{one.num}}</div>
                        <div class="number" v-else>{{one.num}}</div>
                        <div>{{one.info}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail">
            <span class="title">运营状态</span>
            <el-divider></el-divider>
            <div>
                <operation-status></operation-status>
            </div>
        </div>
    </div>
</template>

<script>
    import Signal from "./components/Signal.vue";
    import OperationStatus from "./components/OperationStatus.vue";
    import { getCompleteAddressByPoint } from "@/common/js/BMapUtil";
    import ProgressBar from "./components/Progress";
    import gcoord from "gcoord";

    import bus_background from "@/assets/serviceMap/rightAside/bus_background.png";
    import driver_background from "@/assets/serviceMap/rightAside/driver_background.png";
    export default {
        methods: {
            init(options) {
                this.vehicleNo = options.plateNum;
                this.lng = options.lng;
                this.lat = options.lat;
                const [lng, lat] = gcoord.transform([this.lng, this.lat], gcoord.WGS84, gcoord.BD09);
                getCompleteAddressByPoint(lng, lat).then(rs => {
                    this.address = rs.address;
                });
                this.time = this.$util.fixedMacTime(options.time);
                // this.speed = options.spd;
                // this.orgName = options.orgName;
            },
            update(data) {
                this.vehicleNo = data.vhNo;
                this.lng = data.lng;
                this.lat = data.lat;
                this.speed = data.speed;
                this.time = this.$util.fixedMacTime(data.time);
                const [lng, lat] = gcoord.transform([this.lng, this.lat], gcoord.WGS84, gcoord.BD09);
                this.orgName = data.orgNa;
                getCompleteAddressByPoint(lng, lat).then(rs => {
                    this.address = rs.address;
                });
            },
            itemClick(one) {
                if(one.canClick===undefined?this.canClick:one.canClick){
                    this.$emit('itemClick',one)
                }
            }
        },
        data() {
            return {
                vehicleNo: "",
                customizeNo: "-",
                orgName: "",
                speed: "",
                soc: "",
                pCount: "",
                signal: 3,
                lng: "",
                lat: "",
                address: "",
                time: "",

                driverName: "-",
                phoneNo: "-",
                heartRate: "-",
                minbp: "-",
                maxbp: "-",
                rankScore: "-",
                countScore: "-",

                bus_background,
                driver_background,
                detailList:[
                    {
						"icon": require("@/assets/serviceMap/rightAside/serverMils.png"),
						"info": "营运里程(公里)",
						"num": '682',
                        "name":"routeNum",
                        "canClick": true,
					},
                    {
						"icon": require("@/assets/serviceMap/rightAside/no_serverMils.png"),
						"info": "非营运里程(公里)",
						"num": '50',
                        "name":"routeLen",
                        "canClick": true,
					},{
						"icon": require("@/assets/serviceMap/rightAside/totalShift.png"),
						"info": "计划趟次",
						"num": '50',
                        "name":"totalShift",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/serviceMap/rightAside/relShift.png"),
						"info": "实际趟次",
						"num": '30',
                        "name":"exShift",
                        "canClick": false,
					},{
						"icon": require("@/assets/serviceMap/rightAside/lackShift.png"),
						"info": "缺班趟次",
						"num": '20',
                        "name":"lackShift",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/home/png/line.png"),
						"info": "准点趟次",
						"num": '20',
                        "name":"ontimes",
                        "canClick": false,
					}
                ],
            };
        },
        components: {
            Signal,
            ProgressBar,
            OperationStatus
        },
        computed: {
            // ...mapGetters(["wsType", "wsContent", "wsCount"]),
            getYM() {
                return new Date().Format("yyyy年MM月");
            }
        }
        // watch: {
        //     wsCount() {
        //         if (this.wsType == "subscribePos" && this.wsContent) {
        //             this.speed = this.wsContent.speed;
        //             const p = this.$gcoord.transform(
        //                 [this.wsContent.longitude, this.wsContent.latitude],
        //                 this.$gcoord.WGS84, // 当前坐标系
        //                 this.$gcoord.BD09
        //             );
        //             (this.lng = p[0]), (this.lat = p[1]);
        //             getCompleteAddressByPoint(this.lng, this.lat).then(rs => {
        //                 this.address = rs.address;
        //             });
        //         } else if (this.wsType == "subscribeGB32960" && this.wsContent) {
        //             if (this.wsContent.gb32960Data) {
        //                 const gbdata = new GB32960(this.wsContent.gb32960Data);
        //                 this.speed = gbdata.overallVehData.currentSpeed;
        //                 this.soc = gbdata.overallVehData.socStatus;
        //                 this.pCount = gbdata.person;

        //                 const p = this.$gcoord.transform(
        //                     [gbdata.lng, gbdata.lat],
        //                     this.$gcoord.WGS84, // 当前坐标系
        //                     this.$gcoord.BD09
        //                 );
        //                 (this.lng = p[0]), (this.lat = p[1]);
        //                 getCompleteAddressByPoint(this.lng, this.lat).then(rs => {
        //                     this.address = rs.address;
        //                 });
        //             }
        //         } else if (this.wsType == "subscribeAlarm" && this.wsContent) {
        //             // 车辆报警数据
        //         }
        //     }
        // }
    };
</script>
<style lang="scss" scoped>
    .basic_wrapper {
        padding: 0 20px;
        width: 100%;
        max-height: 600px;
        overflow-y: auto;
        .basic_header {
            img {
                width: 240px;
            }
            .basic_header_right {
                display: inline-block;
                width: calc(100% - 240px);
                .basic_speed {
                    & > span:nth-child(1) {
                        font-family: Digital;
                        font-size: 64px;
                        color: #3879ff;
                        font-weight: bold;
                    }
                    & > span:last-child {
                        color: #c8c8c8;
                        font-size: 18px;
                        margin-left: 5px;
                    }
                }
                .basic_signal {
                    & > span:last-child {
                        color: #c8c8c8;
                        font-size: 16px;
                        margin-left: 5px;
                    }
                }
            }
        }

        .basic_vehicle_info {
            font-size: 16px;
            & ::v-deep .basic_vehicle_info_label {
                width: 70px;
                text-align: right;
                color: #c8c8c8;
            }
            & ::v-deep .basic_vehicle_info_content {
                color: #3879ff;
            }
        }

        .basic_driver_info {
            font-size: 16px;
            & ::v-deep .basic_driver_info_label {
                color: #c8c8c8;
            }
            & ::v-deep .basic_driver_info_content {
                color: #3879ff;
                & > span:last-child {
                    background-color: #00cf8b;
                    color: #fff;
                    border-radius: 3px;
                    margin-left: 5px;
                    padding: 3px 5px;
                }
            }
        }
        .detail {
            box-shadow: #dcdcdc 0px 0px 7px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;
            margin-bottom: 20px;
            .title {
                margin-left: 10px;
                padding-top: 10px;
                font-size: 18px;
                font-weight: bold;
            }
            .detail_item{
                // flex: 1;
                // display: flex;
                padding: 10px;
                width: 145px;
                display: inline-flex;
                .img{
                    height: 45px;
                }
                .text {
                    padding-left: 10px;
                    // display: flex;
                    flex-direction: column;
                    .number {
                        flex:1;
                        font-weight: bold;
                        font-size: 18px;
                        color:#5A5A5A;
                        + div {
                            color: #B3B8CE;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .number1 {
                        flex:1;
                        font-weight: bold;
                        font-size: 18px;
                        color:#04b5fc;
                        cursor: pointer;
                        + div {
                            color: #B3B8CE;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
</style>
