<template>
    <div class="totalBox">
        <jy-query class="query" ref="checkForm" :model="formInline">
            <jy-query-item label="开始日期" prop="sDate" span="1.5">
                <el-date-picker v-model="formInline.sDate" :picker-options="startTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item label="结束日期" prop="eDate" span="1.5">
                <el-date-picker v-model="formInline.eDate" :picker-options="endTime" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                </el-date-picker>
            </jy-query-item>
            <jy-query-item prop="routeId" label="线路" span="1.5">
                <el-select v-model="formInline.routeIds" multiple>
                    <el-option v-for="one in routeList" v-bind:key="one.routeId" :label="one.cName" :value="one.routeId"></el-option>
                </el-select>
            </jy-query-item>
            <jy-query-item prop="vehicleNo" label="司机" span="1.5">
                <el-select v-model="formInline.driverId">
                    <el-option v-for="one in driverList" v-bind:key="one.driverId" :label="one.cName" :value="one.driverId"></el-option>
                </el-select>
            </jy-query-item>
            <template slot="rightCol">
                <el-button type="primary" @click="oncheck">查询</el-button>
            </template>
        </jy-query>
        <el-table ref="jyTable" class="table" :data="dataList" :summary-method="getSummaries" show-summary>
            <jy-table-column type="index" label="序号" width="60" fixed></jy-table-column>
            <jy-table-column prop="date" label="日期" min-width="100"></jy-table-column>
            <jy-table-column prop="routeNa" label="线路" min-width="100" ></jy-table-column>
            <jy-table-column prop="driverNa" label="司机" min-width="100" ></jy-table-column>
            <jy-table-column prop="kilometre" label="营运里程" min-width="100"></jy-table-column>
            <jy-table-column prop="outKilometre" label="非营运里程" min-width="100"></jy-table-column>
            <jy-table-column prop="planShift" label="计划班次" min-width="100"></jy-table-column>
            <jy-table-column prop="relShift" label="实际趟次" min-width="100" ></jy-table-column>
            <jy-table-column prop="lackShift" label="缺班趟次" min-width="100" ></jy-table-column>
            <jy-table-column prop="ontimes" label="准点趟次" min-width="100" ></jy-table-column>
            <jy-table-column prop="exShiftRate" label="计划完成率" min-width="100" ></jy-table-column>
            <jy-table-column prop="lackShiftRate" label="缺班率" min-width="100" ></jy-table-column>
            <jy-table-column prop="ontimesRate" label="发车准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="mPeakCompletionRate" label="早高峰完成率" min-width="100" ></jy-table-column>
            <jy-table-column prop="mPeakPunctualityRate" label="早高峰准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="nPeakCompletionRate" label="晚高峰完成率" min-width="100" ></jy-table-column>
            <jy-table-column prop="nPeakPunctualityRate" label="晚高峰准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="firstShiftCompletionRate" label="首班准点率" min-width="100" ></jy-table-column>
            <jy-table-column prop="endShiftCompletionRate" label="末班准点率" min-width="100" ></jy-table-column>
        </el-table>
        <!-- <jy-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="pageIndex" :page-size="pageSize" :total="total"></jy-pagination> -->
    </div>
</template>
<script>
import {btnMixins} from '@/common/js/button.mixin'
export default {
    data() {
        return {
            routeInfo: '',
            isshow:true,
            formInline: {
                routeIds:'',
                sDate: '',
                eDate: '',
                driverId: ''
            },
            pageSize: 10,
            total: 100,
            pageIndex: 1,
            routeList: [],
            dataList: [],
            driverList: [],
            // btnMenuId:'scheduleStatisticalAnalysisGPStotal',
            startTime: {
                disabledDate:(time) => {
                    if (this.formInline.eDate) {
                        return (
                        time.getTime() > new Date(this.formInline.eDate).getTime()
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
            endTime: {
                disabledDate:(time) => {
                    if (this.formInline.sDate) {
                        return (
                            time.getTime() > Date.now() ||
                            time.getTime() < new Date(this.formInline.sDate).getTime() -8.64e7
                        );
                    } else {
                        return time.getTime() > Date.now();
                    }
                }
            },
        }
    },
    mixins:[btnMixins],
    methods: {
        init(options) {
            this.routeInfo = options
            this.getList()
            this.getRouteList()
            this.driverList=[{
                cName: "史汉良",
                driverId: "1"
            },{
                cName: "宋国颍",
                driverId: "2"
            },{
                cName: "袁坚成",
                driverId: "3"
            },{
                cName: "李金海",
                driverId: "4"
            },{
                cName: "王其生",
                driverId: "5"
            }]
        },
        getSummaries(param) {
            console.log(param)
            const { columns, data } = param;
            const sums = [];
            if(data.length > 0) {
                columns.forEach((column, index) => {
                    let type = ''
                    let num = data[0][column.property]
                    if(index === 0) {
                        sums[index] = '合计';
                        return;
                    }
                    if(num != undefined) {
                        if(index > 2) {
                            if(index > 3 && index < 6) {
                                num = num.substring(0,num.length-2)
                            } else if(index >= 6){
                                num = num.substring(0,num.length-1)
                            }
                            type = Object.prototype.toString.call(Number(num))
                        } else {
                            type = Object.prototype.toString.call(data[0][column.property])
                        }
                    } else {
                        type = Object.prototype.toString.call(data[0][column.property])
                    }
                    console.log(type.slice(8,-1))
                    if(type && type.slice(8,-1)=='Number'){
                        let total = 0
                        data.forEach(item=>{
                            let val = item[column.property]
                            if(index > 3 && index < 6) {
                                val = val.substring(0,val.length-2)
                            } else if(index >= 6){
                                val = val.substring(0,val.length-1)
                            }
                            total = total+Number(val)
                        })
                        if(isNaN(total)) {
                            sums[index] =''
                        } else {
                            if(index > 3 && index < 6) {
                                total = total+ "km"
                            } else if(index >= 6 && index < 10) {
                                total = total+ "次"
                            } else if(index >= 10){
                                total = total+ "%"
                            }
                            sums[index] = total
                        }
                    }else{
                        sums[index] =''
                    }
                });
            }
            return sums
        },
        getRouteList() {
            let url = '/route/info/querypage'
            let option = {
                orgId: this.routeInfo.id,
                pageIndex: 1,
                pageSize: 10
            }
            this.$http.post(url,option).then(({ detail }) => {
                console.log(detail)
                if(detail.list.length > 0) {
                    let list = detail.list.map(item=> {
                        return {
                            ...item.routeInfoVO
                        }
                    })
                    this.routeList = list
                }
            })
        },
        getList() {
            this.dataList = [{
                date:"2022-04-08",
                routeNa:"901路",
                driverNa: "史汉良",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"15%",
                lackShiftRate:"15%",
                ontimesRate:"18%",
                mPeakCompletionRate:"10%",
                mPeakPunctualityRate:"10%",
                nPeakCompletionRate:"10%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"20%",
                endShiftCompletionRate:"10%"
            },{
                date:"2022-04-08",
                routeNa:"901路",
                driverNa: "宋国颍",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"15%",
                lackShiftRate:"15%",
                ontimesRate:"18%",
                mPeakCompletionRate:"10%",
                mPeakPunctualityRate:"20%",
                nPeakCompletionRate:"10%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"20%",
                endShiftCompletionRate:"10%"
            },{
                date:"2022-04-08",
                routeNa:"901路",
                driverNa: "袁坚成",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"15%",
                lackShiftRate:"25%",
                ontimesRate:"28%",
                mPeakCompletionRate:"10%",
                mPeakPunctualityRate:"10%",
                nPeakCompletionRate:"20%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"10%",
                endShiftCompletionRate:"10%"
            },{
                date:"2022-04-08",
                routeNa:"901路",
                driverNa: "李金海",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"25%",
                lackShiftRate:"15%",
                ontimesRate:"18%",
                mPeakCompletionRate:"20%",
                mPeakPunctualityRate:"10%",
                nPeakCompletionRate:"20%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"20%",
                endShiftCompletionRate:"10%"
            },{
                date:"2022-04-08",
                routeNa:"901路",
                driverNa: "王其生",
                kilometre:"1500km",
                outKilometre:"20km",
                planShift:"72次",
                relShift:"22次",
                lackShift:"49次",
                ontimes:"32次",
                exShiftRate:"25%",
                lackShiftRate:"25%",
                ontimesRate:"18%",
                mPeakCompletionRate:"10%",
                mPeakPunctualityRate:"10%",
                nPeakCompletionRate:"10%",
                nPeakPunctualityRate:"20%",
                firstShiftCompletionRate:"10%",
                endShiftCompletionRate:"10%"
            }]
        },
        // 查询表格
        oncheck() {
            this.pageIndex = 1
            this.getList()
        },
        // 重置
        resetForm(formName) {
            this.$refs[formName].resetFields()
        },
        // 分页
        handleSizeChange(val) {
            this.pageSize = val
            this.getList()
        },
    }
}

</script>
<style lang="scss" scoped="totalBox">
.totalBox {
    max-height: 680px;
    .query {
        margin-top:10px;
    }
    .table {
        margin-top:10px;
        font-size: 12px;
    }
}
</style>
