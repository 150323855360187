<template>
    <div class="basic_wrapper">
        <div>
            <el-form :model="diaform" ref="dialogForm" >
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="线路名称:" prop="num">
                            <label>{{diaform.cName}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属机构:" prop="cName">
                            <label>{{diaform.orgNa}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="线路类型:" prop="num">
                            <label>{{diaform.rType}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="运营类型:" prop="num">
                            <label>{{diaform.opType}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="线路状态:" prop="cName">
                            <label>{{diaform.state}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="线路里程:" prop="num">
                            <label>{{diaform.routeLen}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="所属省份:" prop="num" >
                            <label>{{diaform.province}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="所属城市:" prop="cName" >
                            <label>{{diaform.bCity}}</label>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="上行起点:" prop="num" >
                            <label>{{diaform.upStart}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="上行终点:" prop="cName" >
                            <label>{{diaform.upEnd}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="站点数量:" prop="num" >
                            <label>{{diaform.upNum}}</label>
                            <el-button class="siteDetail" type="primary" circle  icon="el-icon-s-operation" @click="openSiteDetail"></el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="下行起点:" prop="num" >
                            <label>{{diaform.downStart}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="下行终点:" prop="cName" >
                            <label>{{diaform.downEnd}}</label>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="站点数量:" prop="num" >
                            <label>{{diaform.downNum}}</label>
                            <el-button class="siteDetail" type="primary" circle  icon="el-icon-s-operation" @click="openSiteDetail"></el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="detail">
            <span class="title">数据概览</span>
            <el-divider></el-divider>
            <div class="detail_cont">
                <div class="detail_item" v-for="(one) in detailList" v-bind:key="one.id" v-bind:class="{'hover':one.canClick===undefined?canClick:one.canClick}" v-on:click="itemClick(one)">
                    <slot :data="one">
                        <img :src="one.icon" class="img"/>
                    </slot>
                    <div class="text">
                        <div class="number1" v-if="one.canClick == true">{{one.num}}</div>
                        <div class="number" v-else>{{one.num}}</div>
                        <div>{{one.info}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail">
            <span class="title">运营状态</span>
            <el-divider></el-divider>
            <div>
                <operation-status></operation-status>
            </div>
        </div>
        <div class="foot">
            <el-button type="primary" @click="addPlanShift">新增排班</el-button>
        </div>
        <line-sitedetail ref="sitedetail"></line-sitedetail>
        <add-shift ref="shift"></add-shift>
    </div>
</template>

<script>
import OperationStatus from "./components/OperationStatus.vue";
import {btnMixins} from '@/common/js/button.mixin';
import addShift from '@/components/pages/admin/scheduling/plannedShift/addShift';
import LineSitedetail from '@/components/pages/admin/resourcesAllocation/lineSiteQuery/lineSitedetail';
import AddShift from '../../../../components/pages/admin/scheduling/plannedShift/addShift.vue';
    export default {
        data() {
            return {
                diaform: {
                    cName: '',
                    orgNa: '',
                    rType: '',
                    opType: '',
                    state: '',
                    routeLen: '',
                    province: '',
                    bCity: '',
                    upNum: '',
                    upStart: '',
                    upEnd: '',
                    downNum: '',
                    downStart: '',
                    downEnd: '',
                },
                routeInfo: '',
                downSizes: [],
                total: 0,
                detailList:[
                    {
						"icon": require("@/assets/home/png/site.png"),
						"info": "站点总数",
						"num": '0',
                        "name":"siteNum",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/serviceMap/rightAside/serverMils.png"),
						"info": "营运里程",
						"num": '133km',
                        "name":"kilometre",
                        "canClick": true,
					},{
						"icon": require("@/assets/serviceMap/rightAside/no_serverMils.png"),
						"info": "非营运里程",
						"num": '20km',
                        "name":"outKilometre",
                        "canClick": true,
					},
                    {
						"icon": require("@/assets/serviceMap/rightAside/totalShift.png"),
						"info": "计划趟次",
						"num": '20',
                        "name":"totalShift",
                        "canClick": false,
					},{
						"icon": require("@/assets/serviceMap/rightAside/lackShift.png"),
						"info": "缺班趟次",
						"num": '20',
                        "name":"lackShift",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/home/png/line.png"),
						"info": "准点趟次",
						"num": '30',
                        "name":"ontimes",
                        "canClick": false,
					}
                ],
            };
        },
        components: {
            OperationStatus,
            LineSitedetail,
            addShift,
                AddShift
        },
        mixins:[btnMixins],
        methods: {
            init(options) {
                this.routeInfo = options
                console.log(options)
                this.getRouteInfo(options)
                this.getSiteInfo(options)
                this.getRouteShift(options)
            },
            openSiteDetail() {
                let option = {
                    routeId: this.routeInfo.id,
                    goType: '',
                    cName: '',
                    export: ''
                }
                if(this.btnexist('scheduleBasicRouteSiteExport')) {
                    option.export = true
                } else {
                    option.export = false
                }
                this.$refs.sitedetail.init(option)
            },
            getRouteInfo(options) {
                let url = "/route/info/querypage";
                let params = {
                    cName: options.text,
                    pageIndex: 1,
                    pageSize: 10
                };
                this.$http.post(url, params).then(({ detail }) => {
                    let list = detail.list.map(item=>{
                        item.routeInfoVO.rType = item.routeInfoVO.rType==0?"上下行":"环形";
                        return {
                            ...item.routeInfoVO,
                            ...item.sysOrg
                        }
                    })
                    this.diaform = list[0]
                });
            },
            getSiteInfo(options) {
                let url = "/route/site/queryPage";
                let params = {
                    cName: options.text,
                    pageIndex: 1,
                    pageSize: 10
                };
                this.$http.post(url, params).then(({ detail }) => {
                    if(detail && detail.list) {
                        let list = {
                            downNum: detail.list[0].downNum,
                            upNum: detail.list[0].upNum
                        }
                        this.diaform = {
                            ...this.diaform,
                            ...list
                        }
                        this.getUpAndDown(options,'1')
                        this.getUpAndDown(options,'2')
                    }
                });
            },
            getUpAndDown(options,type) {
                let size = 0;
                if(type == '1') {
                    size = this.diaform.upNum
                } else {
                    size = this.diaform.downNum
                }
                let url = "/route/site/querySiteDetail";
                let params = {
                    goType: type,
                    pageIndex: 1,
                    pageSize: size,
                    routeId: options.id
                };
                this.$http.post(url, params).then(({ detail }) => {
                    if(detail && detail.list) {
                        let list = {}
                        if(type == '1') {
                            list = {
                                upStart: detail.list[0].siteInfo.cName,
                                upEnd: detail.list[size-1].siteInfo.cName
                            }
                            this.downSizes = []
                            detail.list.map(item=> {
                                this.downSizes.push(item.siteInfo.cName)
                            })
                        } else {
                            list = {
                                downStart: detail.list[0].siteInfo.cName,
                                downEnd: detail.list[size-1].siteInfo.cName
                            }
                            detail.list.map(item=>{
                                this.downSizes.push(item.siteInfo.cName)
                            })
                            let total = Array.from(new Set(this.downSizes)).length
                            this.detailList.forEach(item=> {
                                if(item.name == 'siteNum') {
                                    item.num = total
                                }
                            })
                        }
                        this.diaform = {
                            ...this.diaform,
                            ...list
                        }
                    }
                });
            },
            getRouteShift(options) {
                console.log(options)
                let ids = [options.id]
                console.log(this.$util.getNowDate())
                let url = "/stat/route/shift/queryPage"
                let option ={
                    startTime: this.$util.getNowDate(),
                    endTime: this.$util.getNowDate(),
                    routeIds: ids,
                    pageSize: 10,
                    pageIndex: 1
                }
                this.$http.post(url, option).then(({ detail }) => {
                    this.detailList.forEach(item=> {
                        if(detail.list.length > 0) {
                            if(item.name == "totalShift") {
                                item.num = detail.list[0].totalShift
                            }
                            if(item.name == "lackShift") {
                                item.num = detail.list[0].lackShift
                            }
                            if(item.name == "ontimes") {
                                item.num = detail.list[0].ontimes
                            }
                        }
                    })
                });
            },
            addPlanShift() {
                let option = {
                    routeId:this.routeInfo.id,
                    routeNa:this.routeInfo.text,
                    orgId: this.routeInfo.porgId,
                    oDate:this.$util.getNowDate(),
                    schemeId:'',
                }
                this.$refs.shift.init(option,'add')
            },
            itemClick(one) {
                if(one.canClick===undefined?this.canClick:one.canClick){
                    this.$emit('itemClick',one)
                }
            }
        },
    };
</script>
<style lang="scss" scoped="basic_wrapper">
    .el-divider--horizontal {
        margin: 10px 0;
    }
    .basic_wrapper {
        padding: 0 20px;
        width: 100%;
        max-height: 680px;
        overflow-y: auto;
        .siteDetail {
            margin-left: 10px;
        }
        .detail {
            box-shadow: #dcdcdc 0px 0px 7px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;
            margin-bottom: 20px;
            .title {
                margin-left: 10px;
                padding-top: 10px;
                font-size: 18px;
                font-weight: bold;
            }
            .detail_cont {
                justify-content: space-evenly;
            }
            .detail_item{
                // flex: 1;
                // display: flex;
                padding: 10px;
                width: 160px;
                display: inline-flex;
                .img{
                    height: 45px;
                }
                .text {
                    padding-left: 10px;
                    // display: flex;
                    flex-direction: column;
                    .number {
                        flex:1;
                        font-weight: bold;
                        font-size: 18px;
                        color:#5A5A5A;
                        + div {
                            color: #B3B8CE;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .number1 {
                        flex:1;
                        font-weight: bold;
                        font-size: 18px;
                        color:#04b5fc;
                        cursor: pointer;
                        + div {
                            color: #B3B8CE;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
        .foot {
            margin-bottom: 20px;
            height: 100px;
        }
    }
</style>
