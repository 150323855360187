<template>
	<div class="common_popper">
		<slot name="reference"></slot>

		<div class="tooltip" role="tooltip" :id="tooltip_id" aria-hidden="true" @mouseleave="hideTooltipFromtt">
			<slot name="content"></slot>
			<div class="arrow" data-popper-arrow></div>
		</div>
	</div>
</template>

<script>
	// 显示组件，支持全屏显示
	import { getUUID } from "./utils";
	import { createPopper } from "@popperjs/core";
	export default {
		props: {
			trigger: {
				type: String,
				default: "hover",
			},
		},
		data() {
			return {
				tooltip_id: "",

				reference: null,
				tooltip: null,
				isInTooltip: false,

				timeout: null,

				popperIns: null,
			};
		},
		methods: {
			showTooltip() {
				this.tooltip && this.tooltip.setAttribute("aria-hidden", false);
			},
			hideTooltipFromtt() {
				this.tooltip && this.tooltip.setAttribute("aria-hidden", true);
				this.isInTooltip = false;
			},
			hideTooltipFromrf() {
				if (this.timeout) {
					clearTimeout(this.timeout);
				}
				this.timeout = setTimeout(() => {
					if (!this.isInTooltip) {
						this.tooltip &&
							this.tooltip.setAttribute("aria-hidden", true);
						this.isInTooltip = false;
					}
				}, 300);
			},
			inTooltip() {
				this.isInTooltip = true;
			},
			update() {
				this.popperIns.update();
			},
		},
		created() {
			this.tooltip_id = "tooltip_" + getUUID();
		},
		mounted() {
			this.reference = this.$slots.reference[0].elm;
			this.tooltip = document.getElementById(this.tooltip_id);
			if (this.reference) {
				this.popperIns = createPopper(this.reference, this.tooltip, {
					placement: "top",
					modifiers: [
						{
							name: "offset",
							options: {
								offset: [0, 10],
							},
						},
					],
				});
				if (this.trigger == "hover") {
					this.reference.addEventListener(
						"mouseenter",
						this.showTooltip,
						false
					);
				} else if (this.trigger == "click") {
					this.reference.addEventListener(
						"click",
						this.showTooltip,
						false
					);
				}
				this.reference.addEventListener(
					"mouseleave",
					this.hideTooltipFromrf,
					false
				);
				this.tooltip.addEventListener("mouseenter", this.inTooltip, false);
			}
		},
		destroyed() {
			if (this.reference) {
				if (this.trigger == "hover") {
					this.reference.removeEventListener(
						"mouseenter",
						this.showTooltip,
						false
					);
				} else if (this.trigger == "click") {
					this.reference.removeEventListener(
						"click",
						this.showTooltip,
						false
					);
				}
				this.reference.removeEventListener(
					"mouseleave",
					this.hideTooltipFromrf,
					false
				);
				this.tooltip.removeEventListener(
					"mouseenter",
					this.inTooltip,
					false
				);
			}
			if (this.popperIns) {
				this.popperIns.destroy();
				this.popperIns = null;
			}

			this.reference = null;
			this.tooltip = null;
			this.tooltip_id = "";
		},
	};
</script>
<style lang="scss" scoped>
	.common_popper {
		display: inline-block;
		margin-left: 5px;
		margin-right: 5px;
	}

	.tooltip {
		display: inline-block;
		background: #ffffff;
		color: #643045;
		font-weight: bold;
		padding: 5px 10px;
		font-size: 13px;
		border-radius: 4px;
	}

	.tooltip[aria-hidden="true"] {
		visibility: hidden;
		pointer-events: none;
		& > .arrow {
			visibility: hidden;
		}
	}

	.tooltip[aria-hidden="false"] {
		visibility: visible;
		pointer-events: visible;
		& > .arrow {
			visibility: visible;
		}
	}

	.arrow::before {
		content: "";
		transform: rotate(45deg);
		position: absolute;
		width: 8px;
		height: 8px;
		top: -8px;
		left: -4px;
		background: #ffffff;
	}

	.tooltip[data-popper-placement^="top"] > .arrow {
		bottom: -4px;
	}

	.tooltip[data-popper-placement^="bottom"] > .arrow {
		top: -4px;
	}

	.tooltip[data-popper-placement^="left"] > .arrow {
		right: -4px;
	}

	.tooltip[data-popper-placement^="right"] > .arrow {
		left: -4px;
	}
</style>