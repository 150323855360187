<template>
    <div class="progress_wrapper">
        <div class="progress_bar">
            <div class="progress_value" :style="{ width: Math.min(value, 100) + '%' }"></div>
        </div>
        <span class="label">电量</span>
        <span class="digital_value">{{ Math.min(value, 100) }}</span>
        <span class="label">%</span>
    </div>
</template>

<script>
    export default {
        props: {
            value: {
                type: [String, Number],
                default: "0"
            }
        }
    };
</script>
<style lang="scss" scoped>
    .progress_wrapper {
        text-align: center;
        .progress_bar {
            display: inline-block;
            width: 200px;
            height: 20px;
            background: #c8c8c8;
            .progress_value {
                background: #3879ff;
                height: 100%;
            }
        }
        .digital_value {
            font-family: Digital;
            font-size: 30px;
            color: #3879ff;
        }
        .label {
            color: #c8c8c8;
            font-size: 16px;
            margin: 0 5px;
        }
    }
</style>
