<template>
    <div class="basic_wrapper">
        <div class="basic_driver_info">
            <el-row>
                <el-col :span="16">
                    <el-form :model="diaform" ref="dialogForm" >
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="司机姓名:" prop="cName">
                                    <label>{{diaform.cName}}</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="所属组织:" prop="orgNa">
                                    <label>{{diaform.orgNa}}</label>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="司机工号:" prop="wNum">
                                    <label>{{diaform.wNum}}</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="考勤卡号:" prop="cwaNo">
                                    <label>{{diaform.cwaNo}}</label>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="入职时间:" prop="eDate">
                                    <label>{{diaform.eDate}}</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="离职时间:" prop="rDate">
                                    <label>{{diaform.rDate}}</label>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="供职状态:" prop="sState">
                                    <label>{{diaform.sState=='0'?"离职":"在职"}}</label>
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item label="联系电话:" prop="phone">
                                    <label>{{diaform.phone}}</label>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="12">
                                <el-form-item label="绑定车辆:" prop="carPlateNums">
                                    <label>{{diaform.carPlateNums}}</label>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </el-col>
                <el-col :span="8" style="margin-top: 30px;">
                    <img :src="driver_background" />
                </el-col>
            </el-row>
        </div>
        <div class="relShif">
            <div class="shift_title">
                <span>实时班次</span>
                <span style="color:green;">901路-上行</span>
                <span>浙B5A703</span>
                <span>实际发车时间：06:40</span>
            </div>
            <el-divider></el-divider>
            <div>
                <el-row >
                    <el-col :span="18">
                        <div class="shift">
                            <div class="shift_item">
                                <span>湖心景区</span>
                                <span>06:40</span>
                            </div>
                            <div><img :src="goto" /></div>
                            <div class="shift_item">
                                <span>汽车东站</span>
                                <span>07:50</span>
                            </div>
                        </div>
                        <div class="mills">
                            <el-row>
                                <el-col :span="14" style="padding-left:22px;">
                                    <el-progress :text-inside="true" :stroke-width="18" :percentage="70"></el-progress>
                                </el-col>
                                <el-col :span="10" style="padding-left:5px;">
                                    <span style="margin-left:5px;">14.0km/24.0km</span>
                                    <el-button style="margin-left:2px;" type="primary" circle  icon="el-icon-s-operation" @click="openSiteDetail"></el-button>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="site">
                            <span>上一站：中医院</span>
                            <span>当前站：百捷商城</span>
                            <span>下一站：江南公园</span>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="speed" >
                            <div ref="speed" class="speed-chart"></div>
                            <div>当前时速 {{ Math.floor(30) }}km/h</div>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </div>
        <div class="detail">
            <span class="title">数据概览</span>
            <el-divider></el-divider>
            <div>
                <div class="detail_item" v-for="(one) in detailList" v-bind:key="one.id">
                    <slot :data="one">
                        <img :src="one.icon" class="img"/>
                    </slot>
                    <div class="text">
                        <div class="number1" v-if="one.canClick == true">{{one.num}}</div>
                        <div class="number" v-else>{{one.num}}</div>
                        <div>{{one.info}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="detail">
            <span class="title">运营状态</span>
            <el-divider></el-divider>
            <div>
                <operation-status></operation-status>
            </div>
        </div>
        <waybill-detail ref="waybill"></waybill-detail>
    </div>
</template>

<script>
import * as Echarts from "echarts";
import OperationStatus from "./components/OperationStatus.vue";
import {btnMixins} from '@/common/js/button.mixin';
import driver_background from "@/assets/serviceMap/rightAside/driver_background.png";
import goto from "@/assets/serviceMap/rightAside/goto.png";
import waybillDetail from "./components/waybillDetail.vue";
    export default {
        data() {
            return {
                diaform: {
                    cName: '',
                    orgNa: '',
                    wNum: '',
                    cwaNo: '',
                    eDate: '',
                    rDate: '',
                    sState: '',
                    phone: '',
                    carPlateNums: ''
                },
                waybillId:"625e55d3e4b0dd68899b6f5d",
                driver_background,
                goto,
                routeInfo: '',
                downSizes: [],
                total: 0,
                detailList:[
                    {
						"icon": require("@/assets/serviceMap/rightAside/serverMils.png"),
						"info": "营运里程(公里)",
						"num": '682',
                        "name":"routeNum",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/serviceMap/rightAside/no_serverMils.png"),
						"info": "非营运里程（公里）",
						"num": '50',
                        "name":"routeLen",
                        "canClick": false,
					},{
						"icon": require("@/assets/serviceMap/rightAside/totalShift.png"),
						"info": "计划趟次",
						"num": '50',
                        "name":"totalShift",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/serviceMap/rightAside/relShift.png"),
						"info": "实际趟次",
						"num": '30',
                        "name":"exShift",
                        "canClick": false,
					},{
						"icon": require("@/assets/serviceMap/rightAside/lackShift.png"),
						"info": "缺班趟次",
						"num": '20',
                        "name":"lackShift",
                        "canClick": false,
					},
                    {
						"icon": require("@/assets/home/png/line.png"),
						"info": "准点趟次",
						"num": '20',
                        "name":"ontimes",
                        "canClick": false,
					}
                ],
            };
        },
        components: {
            OperationStatus,
            waybillDetail
        },
        mixins:[btnMixins],

        methods: {
            init(options) {
                this.routeInfo = options
                this.getDriverInfo(options)
                this.getWayBillList()
                this.$nextTick(() => {
                    this.drawSpeed(30);
                });
            },
            getDriverInfo(options) {
                let url = "/base/driver/queryPage";
                let params = {
                    cName: options.text,
                    pageIndex: 1,
                    pageSize: 10
                };
                this.$http.post(url, params).then(({ detail }) => {
                    console.log(detail)
                    let list = detail.list.map(item=>{
                        item.driverInfo.eDate = item.driverInfo.eDate== ''?"":item.driverInfo.eDate.substring(0,11)
                        return {
                            ...item.driverInfo
                        }
                    })
                    list.forEach(i=>{
                        if(i.driverId == options.id) {
                            this.diaform = i
                        }
                    })
                });
            },
            getList() {
                let url = "/home/basicView"
                this.$http.post(url).then(({ detail }) => {
                    for(let i in detail) {
                        this.detailList.forEach(item=>{
                            if(item.name == i) {
                                item.num = detail[i]
                            }
                        })
                    }
                });
            },
            getWayBillList() {
                let url = "/waybillActual/queryPage"
                let param = {
                    pageIndex: 1,
                    pageSize: 10,
                    driverNa: this.routeInfo.text,
                    eState: "1"
                }

                this.$http.post(url,param).then(({ detail }) => {
                    console.log(detail)
                });
            },
            drawSpeed(spd) {
                const dom = this.$refs.speed;
                const chart = Echarts.init(dom);
                const option = {
                    series: [
                        {
                            type: "gauge",
                            min: 0,
                            max: 200,
                            splitNumber: 10,
                            radius: "65%",
                            axisLine: {
                                lineStyle: {
                                    width: 2,
                                    color: [
                                        [0.2, "green"], // 0~10% 红轴
                                        [0.4, "orange"], // 10~20% 绿轴
                                        [1, "red"]
                                    ]
                                }
                            },
                            splitLine: {
                                length: 5,
                                lineStyle: {
                                    width: 1
                                }
                            },
                            axisTick: {
                                show: false
                            },
                            axisLabel: {
                                distance: -35,
                                color: "#f00"
                            },
                            pointer: {
                                offsetCenter: [0, "10%"],
                                icon: "path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z",
                                length: "115%",
                                itemStyle: {
                                    color: "#000"
                                }
                            },
                            detail: {
                                valueAnimation: true,
                                precision: 1,
                                offsetCenter: [0, "90%"]
                            },
                            title: {
                                offsetCenter: [0, "-40%"]
                            },
                            data: [
                                {
                                    value: Math.floor(spd),
                                    name: "km/h"
                                }
                            ]
                        }
                    ]
                };
                chart.setOption(option);
            },
            openSiteDetail() {
                this.$refs.waybill.init(this.waybillId)
            },
        },
    };
</script>
<style lang="scss" scoped>
    .el-divider--horizontal {
        margin: 10px 0;
    }
    .basic_wrapper {
        padding: 0 20px;
        width: 100%;
        max-height: 510px;
        overflow-y: auto;

        .relShif {
            box-shadow: #dcdcdc 0px 0px 7px;
            .shift_title {
                display: flex;
                justify-content: space-evenly;
                padding-top: 20px;
            }
            .shift {
                margin-top: 10px;
                display: flex;
                .shift_item {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;
                    text-align: center;
                }
            }
            .mills {
                margin-top: 30px;
            }
            .site {
                display: flex;
                justify-content: space-evenly;
                margin-top: 30px;
                margin-bottom: 20px;
            }
            .speed{
                flex: 2;
                text-align: center;
                display: flex;
                flex-direction: column;
                margin-bottom: 20px;
                .speed-chart {
                    flex: 1;
                    min-height: 130px;
                }
            }
        }

        .detail {
            box-shadow: #dcdcdc 0px 0px 7px;
            display: flex;
            flex-wrap: wrap;
            margin-top: 16px;
            margin-bottom: 20px;
            .title {
                margin-left: 10px;
                padding-top: 10px;
                font-size: 18px;
                font-weight: bold;
            }
            .detail_item{
                // flex: 1;
                // display: flex;
                padding: 10px;
                width: 220px;
                display: inline-flex;
                .img{
                    height: 45px;
                }
                .text {
                    padding-left: 10px;
                    // display: flex;
                    flex-direction: column;
                    .number {
                        flex:1;
                        font-weight: bold;
                        font-size: 18px;
                        color:#5A5A5A;
                        + div {
                            color: #B3B8CE;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                    .number1 {
                        flex:1;
                        font-weight: bold;
                        font-size: 18px;
                        color:#04b5fc;
                        cursor: pointer;
                        + div {
                            color: #B3B8CE;
                            font-size: 14px;
                            font-weight: 600;
                        }
                    }
                }
            }
        }
    }
</style>
