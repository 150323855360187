<template>
    <jy-dialog :title="title" :visible.sync="dialogVisible" type="table" :width="'1000px'">
        <jy-table :data="dataList" :max-height="600">
            <jy-table-column prop="siteNa" label="站点名称"  show-overflow-tooltip></jy-table-column>
            <jy-table-column prop="aTime" label="进站时间"  :formatter="timeFil"></jy-table-column>
            <jy-table-column prop="lTime" label="离站时间"  :formatter="timeFil"></jy-table-column>
            <jy-table-column prop="stopTime" label="停留时间(秒)"></jy-table-column>
            <jy-table-column prop="spd" label="进站速度(km/h)" ></jy-table-column>
        </jy-table>
    </jy-dialog>
</template>
<script>
export default {
    data() {
        return {
            title: '过站明细',
            dialogVisible: false,
            waybillId: '',
            dataList:[],
        }
    },
    methods: {
        init(option) {
            this.waybillId = option
            this.dialogVisible = true
            this.getList()
        },
        schemeTFil(row,column,cellVal){
            if(cellVal == '1'){
                return '上行'
            }else if (cellVal == '2'){
                return '下行'
            }else{
                return '环形'
            }
        },
        timeFil(row,column,cellVal){
            if(cellVal){
                return cellVal.split(' ')[1]
            }else{
                return ''
            }
        },
        getList(){
            let url = '/waybillActual/waybillDetail'
            let params={
                waybillId:this.waybillId
            }
            this.$http.post(url,params).then(data=>{
                data.detail.forEach((item,k)=>{
                    data.detail[k] = {
                        ...item,
                    }
                })
                this.dataList = data.detail
            })
        }
    }
}

</script>
