import lamejs from "lamejs";

class MP3 {
  constructor() {
    this.mp3 = new lamejs.Mp3Encoder(1, 8000, 128); //mono 44.1khz encode to 128kbps
    this.ctx = new AudioContext();
  }

  /**
   *
   * @param {Uint8Array} data
   * @returns {Int16Array}
   */
  decode(data) {
    let float32;
    this.ctx.decodeAudioData(data.buffer, function(buffer) {
      float32 = buffer.getChannelData(0);
    });
    return new Int16Array(float32.buffer);
  }

  /**
   *
   * @param {Int16Array} data
   * @returns {Uint8Array}
   */
  encode(data) {
    return new Uint8Array(this.mp3.encodeBuffer(data));
  }

  destroy() {
    if (this.ctx) {
      this.ctx.close();
      this.ctx = null;
    }
  }
}

export default MP3;
